import React, { Component } from 'react';

import Loader from 'components/Loader';

export default function asyncComponent (importComponent) {
    class AsyncComponent extends Component {
        constructor(props) {
            super(props);
            
            this.state = {
                loading: true,
                component: null
            };
        }

        async componentDidMount() {
            const { default: component } = await importComponent();
            this.setState({
                loading: false,
                component: component
            });
        }
        
        render() {
            const C = this.state.component
            return C 
            ? <C {...this.props} /> 
            : <Loader 
                isLoading={this.state.loading} 
                header="Loading page"
                />;
        }
    }

    return AsyncComponent
}

