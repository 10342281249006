import React, { Component } from 'react';
import { Container, Button } from 'reactstrap';
import logo from '../assets/LUXEIN.svg';

export default class NoInternet extends Component {
  refresh () {
    document.location.reload();
  }
  render () {
    return (
      <Container fluid className='bg_primary py-3 d-flex' style={{ minHeight: '100vh' }}>
        <div className='m-auto text-center'>
          <img
            className='logo mx-auto mb-5'
            src={logo}
            style={{
              width: '200px',
            }}
            alt='luxein'
          />
          <h1 className='text-center tc_onprimary'>Ooops!</h1>
          <div className='text-center tc_onprimary'>
            <p className='mb-3'>Please check your internet connection and retry.</p>
            <Button className='btn_accent rounded-0' onClick={this.refresh}>
              Retry
            </Button>
          </div>
        </div>
      </Container>
    );
  }
}
