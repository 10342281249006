
// Analytics Libs
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import ReactGTM from 'react-gtm-module'

import config from './config'

// setup environment
const env = process.env.REACT_APP_ENV;
const url = config[env];

function initialize () {
    initFBPixel();
    initGoogleAnalytics();
    initGoogleTagManager();
}

function initFBPixel () {
    // FACEBOOK REACT PIXEL
    ReactPixel.init(url.fbPixelID, {}, { debug: url.debug, autoConfig: true });
    ReactPixel.pageView();
    ReactPixel.fbq('track', 'PageView');
}

function initGoogleAnalytics () {
    //GOOGLE ANALYTICS
    ReactGA.initialize(url.gaID, { debug: url.debug });
}

function initGoogleTagManager () {
    //GOOGLE TAG MANAGER
    const tagManagerArgs = {
        gtmId: url.gtmID
    }
    // debugger

    ReactGTM.initialize(tagManagerArgs)
}

function triggerDataLayer (args) {
    ReactGTM.dataLayer(args)
}

function triggerGAEvent(item, event){
    ReactGA.gtag("event", event, {
      currency: "PHP",
      value: item.price,
      items: [
        {
          item_id: item.sku,
          item_name: item.name,
          affiliation: "Google Merchandise Store",
          coupon: "",
          discount: "",
          item_brand: item.Product_brand,
          price: item.price,
          quantity: 1
        }
      ]
    });
  }
export const Analytics = {
    init: initialize,
    triggerGTM: triggerDataLayer,
    gaEvent: triggerGAEvent
}