const reduceOpacity = (color) => {
  // let kulay = color.replace(/#/g, "#00");
  let kulay = `${color}33`;
  return kulay;
};

export const colorTheme = ({
  primary = "#333333",
  primary_variant = "#444444",
  secondary = "#ea4c89",
  facebook = "#4267B2",
  tertiary = "#252525",
  background = "#F5F5F5",
  surface = "#FFFFFF",
  on_primary = "#FFFFFF",
  on_secondary = "#FFFFFF",
  on_background = "#333333",
  on_surface = "#333333",
}) => {
  return `
        .bg{
            background: ${background}!important;
        }

        .bg_surface {
            background: ${surface}!important;
        }

        .bg_primary {
            background: ${primary}!important;
        }

        .bg_primary-variant {
            background: ${primary_variant}!important;
        }

        .bg_facebook {
            background: ${facebook}!important;
        }

        // accent color
        .bg_secondary {
            background: ${secondary}!important;
        }

        .bg_lightonbackground {
            background: ${reduceOpacity(on_background)}!important;
        }

        // border color
        .bc_lightonbackground{
            // border-color: pink!important;
            border-color: ${reduceOpacity(on_background)}!important;
        }

        .tc_btn_primary {
            color: ${primary} !important;
        }

        .tc_tertiary {
            color: ${tertiary}!importmant;
        }

        .tc_accent {
            color: ${secondary}!important;
        }

        .tc_surface{
            color: ${on_surface}!important;
        }

        .tc_onbackground{
            color: ${on_background}!important;
        }

        .tc_onprimary {
            color: ${on_primary}!important;
        }

        .tc_onsecondary {
            color: ${on_secondary}!important;
        }

        .tc_onsurface{
            color: ${on_surface}!important;
        }

        .tc_disabled {
            color: #d3d3d3 !important;
        }

        .btn_primary{
            border: solid 1px ${primary}!important;
            background-color: ${primary}!important;
            color: ${on_primary}!important;
            border-radius: 0;
        }

        .btn_primary_nobg {
            border: solid 1px ${primary}!important;
            background: none !important;
            color: ${primary}!important;
            border-radius: 0;
        }



        .btn_lightprimary{
            border: solid 1px ${reduceOpacity(primary)}!important;
            background-color: ${reduceOpacity(primary)}!important;
            color: ${primary}!important;
            border-radius: 0;
        }

        .btn_secondary{
            border: solid 1px ${primary}!important;
            background-color: ${on_primary}!important;
            color: ${primary}!important;
            border-radius: 0;
        }

        .btn_accent{
            border: solid 1px ${secondary}!important;
            background-color: ${secondary}!important;
            color: ${on_secondary}!important;
            border-radius: 0;
        }


        .icon_primary{
            fill: ${primary}!important;
        }
        .icon_secondary{
            fill: ${secondary}!important;
        }
        .icon_onbackground{
            fill: ${on_background}!important;
        }
        .icon_onprimary{
            fill: ${on_primary}!important;
        }
        .icon_onsecondary{
            fill: ${on_secondary}!important;
        }
        .icon_onsurface{
            fill: ${on_surface}!important;
        }

        .icon_disabled_onwhite {
            fill: #d3d3d3 !important;
        }

        .border_primary {
            border: solid 1px ${primary}!important;
        }
        .border_secondary {
            border: solid 1px ${secondary}!important;
        }

        .border_isInvalid {
            border: solid 1px #ff6464!important
        }

        .border_isInvalid:active , .border_isInvalid:focus {
            box-shadow: 0 0 0 0.2rem rgba(255, 100, 100, .25)!important;
        }

        .border_hover_secondary:hover{
            border: solid 1px ${secondary}!important;
        }

        .border_onsurface{
            border: solid 1px ${reduceOpacity(on_surface)}!important;
        }
        .border_onbackground{
            border: solid 1px ${reduceOpacity(on_background)}!important;
        }

        .tc_hover_secondary:hover{
            color: ${secondary}!important;
        }

        .tc_hover_secondary:hover > [class*="tc"]{
            color: ${secondary};
        }

        .tc_hover_secondary:hover [class*="icon"]{
            fill: ${secondary}!important;
        }
        .tc_hover_secondary:hover [class*="icon_white"]{
            fill: ${on_primary}!important;
        }

        a:not(.btn):hover {
            color: ${secondary};
        }

        a.inverted {
            color: ${background}!important;
        }

        a.inverted:hover {
            color: ${background}!important;
            font-weight: bold;
        }


        input:disabled{
            background: ${reduceOpacity(on_background)}!important;
            color: ${on_background}!important;
        }

        // only for quick shopping bag

        .input-range__slider {
            background: ${primary}!important;
            padding:2px;
            border: solid 1px ${primary}!important;
        }
        .input-range__track--active {
            background: ${primary}!important;
        }
        .input-range__label-container {
            color: ${primary}!important;
            font-style:'Montserrat', sans-serif !important;
            font-size:14px;
        }
        .css-19bqh2r{
            fill: ${on_background}!important;
        }
        .custom-checkbox{
            display: none;
        }
        .custom-control-input:checked~.custom-control-label::before{
            background-color: ${primary}!important;
        }
        .custom-control-input~.custom-control-label::before{
            border-radius: 2px!important;
            background-color: ${on_primary}!important;
            border: solid 2px ${primary}!important;
        }

        .input-range__track{
            background: ${reduceOpacity(on_background)}!important;
        }

        .input-range__track--active{
            background: ${primary}!important;
        }

        .input-range__slider{
            background-color: #252525!important;
            border: solid 3px #ffffff!important;
            box-shadow: 0px 0px 0px 2px #252525!important;
        }

        .input-range__label-container{
            display: none;
        }

        .custom-checkbox{
            display: flex!important;
        }


        .react-autosuggest__container{
            background: pink!important;
            width: inherit;
            position: relative!important;
        }
        .react-autosuggest__input{
            height: 100%!important;
        }
        .react-autosuggest__suggestions-container{
            position: absolute!important;
            top: 110%!important;
            left: 0!important;
            right: 0!important;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)!important;
            background: ${background}!important;
            overflow: auto!important;
            max-height: 500px!important;
        }
        .react-autosuggest__suggestions-list{
            display: flex!important;
            flex-direction: column!important;
            padding: 0;
            margin: 0;
        }
        .react-autosuggest__suggestion{
            background: ${background}!important;
            text-align: left!important;
            margin: 0!important;
            color: ${on_background}!important;
            display: inline-flex!important;
            justify-content: flex-start!important;
            padding: .375rem .75rem!important;
        }

        .react-autosuggest__suggestion--highlighted{
            background: ${reduceOpacity(on_background)}!important;
        }

        .slick-prev:before, .slick-next:before{
            color: ${primary}!important;
        }
        .star{
            stroke: ${secondary};
            stroke-width: 4px;
            // stroke-linecap: butt;
            // stroke-linejoin: miter;
            stroke-alignment: inner;
        }
        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--in-range{
            background-color: ${primary}!important;
        }

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected{
            background-color: ${primary_variant}!important;
        }

        // .react-datepicker__day--today,
        // .react-datepicker__month-text--today{
        //     background-color: ${surface}!important;
        // }
    `;
};
