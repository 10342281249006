import * as firebase from 'firebase';
import 'firebase/database';

import config from './config';

const env = process.env.REACT_APP_ENV;

function initialize (){
  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: 'AIzaSyAEhmsxw5S8Pa8efjf0kqyecKiAfe2-lVI',
    authDomain: 'luxein-marketplace.firebaseapp.com',
    databaseURL: 'https://luxein-marketplace.firebaseio.com',
    projectId: 'luxein-marketplace',
    storageBucket: 'luxein-marketplace.appspot.com',
    messagingSenderId: '1006755012291',
    appId: '1:1006755012291:web:6761f9e3dd3f6e60',
  };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
}

function checkMaintenance (callback){
  const firebaseKey = config[env].firebaseKey;
  // Get a reference to the root of the Database
  const maintenaceRef = firebase.database().ref(`web/${firebaseKey}`);
  maintenaceRef.on('value', (snapshot) => {
    const flag = snapshot.val() || false;
    callback(flag);
  });
}

export const Firebase = {
  init: initialize,
  check: checkMaintenance,
  key: config[env].firebaseKey,
  fbKey: config[env].fbButtonKey,
  paynamicsKey: config[env].paynamicsButtonKey,
};
