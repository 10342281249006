// import _find from 'lodash.find'
import { getSpecialFlag } from '../utils/helpers'

function getV1TileContents(item) {
    // console.log(item.brand)
    const {
        sku,
        entity_id,
        status,
        name,
        url_key,
        image,
        small_image_label,
        price,
        mhero_new_price,
        stock,
        percent_off,
        special_price,
        favorites
    } = item

    return {
        sku,
        product_id: entity_id,
        is_enabled: status,
        name,
        url_key,
        brand: {
            name: item.brand ? item.brand.brand_name : "None",
            brand_id: item.brand ? item.brand.brand_id : -1,
            url_key: item.brand ? item.brand.brand_url_key : "",
        },
        image,
        small_image: small_image_label || image,
        price,
        special_price,
        percent_off,
        mhero_new_price,
        on_cart: undefined,// not supported at the moment
        on_favorites: favorites,// not supported at the moment
        with_stock: stock,
    }
}

function getV2TileContents(item) {
    if (!item.attribute_groups) return item
    const { brand } = item
    const {
        "advanced-pricing" : {
            price,
            special_price,
            mhero_new_price,
            special_from_date,
            special_to_date
        },
        "product-details" : {
            name,
            status,
            mhero_internal_status
        },
        "image-management" : {
            image,
            small_image_label
        },
        "search-engine-optimization" : {
            url_key
        }
    } = getAttributeGroupObjects(item.attribute_groups)
    const { 
        isSpecial
    } = getSpecialFlag(special_from_date, special_to_date)
    // debugger;
    return {
        sku: item.sku,
        product_id: item.product_id,
        is_enabled: status,
        name,
        url_key,
        // brand
        brand,
        // pricing
        image,
        small_image: small_image_label,
        price,
        special_price: isSpecial ? special_price : undefined,
        percent_off: isSpecial ? item.percent_off || 0 : undefined, 
        mhero_new_price,
        on_cart: item.cart,
        on_favorites: item.favorites,
        with_stock: item.with_stock,
        mhero_internal_status
    }
}

function getTileAttributeGroup () {
    return {
        "advanced-pricing": [
            "price", 
            "special_price", 
            "special_from_date", 
            "special_to_date", 
            "mhero_new_price", 
            "mhero_percent_discount"
        ],
        "product-details": [
            "name", 
            "sku",
            "status",
            "mhero_internal_status",
        ],
        "image-management": [
            "image",
            "small_image_label"
        ],
        "search-engine-optimization": [
            "url_key"
        ]
    }
}


export function parseAttributeGroup(group) {
    const keys = Object.keys(group)
    let attribute_group = keys.map(key => (JSON.stringify({
        "attribute_group": key,
        "attributes": group[key]
    })))
    return `[${attribute_group}]`
}

export function defaultFilterQuery() {
    return [
        {
            "attribute_code":"status",
            "backend_type":"int",
            "operator":"=",
            "value":1
        },
        {
            "attribute_code":"mhero_internal_status",
            "backend_type":"varchar",
            "operator":"=",
            "value":"posted"
        }
    ]
}

export function getAttributeGroupObjects(group) {
    const objects = group.reduce((acc, item) => ({
        ...acc,
        [item.attribute_group_code]: item.attributes.reduce((attrs, item) => {
            if(item){
                return ({
                    ...attrs,
                    [item.attribute_code]: item.value
                })
            } else {
                return attrs
            }
        }, {})
    }), {})
    return objects
}

export const TileManager = {
    getTileV1: getV1TileContents,
    getTileV2: getV2TileContents,
    getAttributeGroup: getTileAttributeGroup
}