import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SyncLoader from 'react-spinners/dist/spinners/SyncLoader';
import logo from '../assets/LUXEIN.svg'
// import logo from 'assets/logo.svg'

class Loader extends Component {
    render() {
        const { 
            isLoading, 
        } = this.props
        return (
            <div className="loader"
                style={
                    {
                        display: isLoading ? "flex" : "none"
                    }
                }>
                <div>
                    <img
                        className='logo mb-3' 
                        src={logo}
                        style={{
                            width: "150px"
                        }}
                        alt="luxein"
                    />
                    {/* <strong>ReactJS BoilerPlate</strong> */}
                    {/* {header && (<strong>{header}</strong>)}
                    <small>{message}</small> */}
                    &nbsp;
                    <SyncLoader
                        size={5}
                        color={"#fff"}
                        loading={isLoading}
                    />
                </div>
            </div>
        );
    }
}

Loader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    message: PropTypes.string,
    header: PropTypes.string,
};

export default Loader;