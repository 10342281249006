import * as Cookie from 'utils/cookie';
import formurlencoded from 'form-urlencoded';
import urls from './config';
import { TileManager, parseAttributeGroup, defaultFilterQuery } from './AttributeManager';
import { copyFile } from 'fs';

const env = process.env.REACT_APP_ENV;
const api = urls[env].route;
// debugger
// console.log(api);
// const api = 'http://192.168.1.2:5000/api/v1'; //ernest
// const api = 'http://192.168.0.5:5000/api/v1'; //melyn
// const api = 'http://galadriel-backend-test.codedisruptors.com:5000/api/v1'; //test

// const api = 'https://api-galadriel.luxein.com/api/v1'; //prod
// let token = Cookie.getToken()

// 'Content-Type': 'application/x-www-form-urlencoded',
// 'Authorization': `Bearer ${token ? token.token : null}`

export const getAPIPaynamics = () => {
  // if (api === 'https://api-galadriel.luxein.com/api/v1') {
  //   return 'https://ptiapps.paynamics.net/webpayment_v2/Default.aspx';
  // } else {
  //   return 'https://testpti.payserv.net/webpaymentv2/default.aspx';
  // }
  return 'https://payment.payserv.net/v2/pay?pid='
};

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
  // 'Content-Type': 'application/json',
};

// REGISTER

export const register = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/customers/register`;
  config.body = formurlencoded(body);
  return queryService(config);
};

// OAuth Login / Register

export const OAuthLoginRegister = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/customers/login/oauth`;
  config.body = formurlencoded(body);
  return queryService(config);
};

// LOGIN

export const login = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/customers/login`;
  config.body = formurlencoded(body);
  return queryService(config);
};

export const facebooklogin = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/auth/facebook`;
  return queryService(config);
};

// export const facebookLoginRedirect = () => {
//     const config = {}

// }

// LOGOUT

export const logout = () => {
  const config = {};
  config.method = 'POST';
  config.route = `/customer/logout`;
  config.require_token = true;
  return queryService(config);
};

// USER

export const getCustomerInfo = (mobToken = '') => {
  const config = {};
  config.method = 'GET';
  config.require_token = true;
  if(mobToken !== ''){
    config.mobToken = mobToken
  }
  config.route = `/customer/information`;
  return queryService(config);
};

export const getCustomerDashboard = () => {
  // const user = Cookie.getUser()
  // const userId = user ? user.user_id : 0
  const config = {};
  config.method = 'GET';
  config.require_token = true;
  config.route = `/customer/dashboard/`;
  return queryService(config);
};

// curl --location --request PUT "http://52.74.192.141:5000/api/v1/customer/information/108" \
//   --header "Content-Type: application/x-www-form-urlencoded" \
//   --data "email=Alb3rtuz@gmail.com&middlename=Castilloo&lastname=Remo&firstname=Albert"
// email
// password
// current_password
// middlename
// lastname
// firstname

export const updateCustomerInfo = (body) => {
  // const user = Cookie.getUser()
  // const userId = user.user_id

  const config = {};
  config.method = 'PUT';
  config.route = `/customer/information/`;
  config.require_token = true;
  config.body = formurlencoded(body);
  return queryService(config);
};

export const getFavorites = () => {
  const config = {};
  config.method = 'GET';
  config.params = {
    limit: 1000,
    // additional_attributes: '{"attributes" : ["category"]}',
    // filter: `[{"attribute_code":"name","backend_type":"varchar","operator":"like","value":""},{"attribute_code":"price","backend_type":"decimal","operator":"between","value":[6000,450000]}]`
  };
  config.require_token = true;
  config.route = `/wishlist-products?limit=1000`;
  return queryService(config);
};

export const getMetaTags = (key) => {
  const config = {};
  config.method = 'GET';
  config.route = `/static_page`;
  config.params = {
    url_key: key,
  };
  return queryService(config);
};

export const addToFavorites = (id) => {
  const config = {};
  config.method = 'POST';
  config.require_token = true;

  config.route = `/favorite/${id}`;
  return queryService(config);
};

export const deleteFavorite = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.require_token = true;

  config.route = `/favorite/${id}`;
  return queryService(config);
};

export const deleteUser = (id) => {
  const config = {
    method: 'DELETE',
    route: `/user/${id}`,
  };
  return queryService(config);
};

export const getAddressBook = () => {
  const config = {
    method: 'GET',
    route: `/customer/address/book`,
  };
  config.require_token = true;
  return queryService(config);
};

export const deleteAddressBook = (id) => {
  const config = {
    method: 'DELETE',
    route: `/customer/address/book/${id}`,
  };
  config.require_token = true;
  return queryService(config);
};

export const getBrandBanner = () => {
  const config = {
    method: 'GET',
    route: `/banner`,
  };
  config.require_token = true;
  return queryService(config);
};

export const postAddressBook = ({ body, params }) => {
  // console.log(body)
  const config = {
    method: 'POST',
    route: `/customer/address-book`,
    customHeader: {
      'Content-Type': 'application/json',
    },
    require_token: true,
    params,
    body,
  };
  return queryService(config);
};

export const editAddressBook = ({ body, params, id }) => {
  const config = {
    method: 'PUT',
    route: `/customer/address-book/${id}`,
    customHeader: {
      'Content-Type': 'application/json',
    },
    require_token: true,
    params,
    body,
  };
  return queryService(config);
};

export const getOrders = (limit, page, sort, status) => {
  const config = {
    method: 'GET',
    route: `/customer/order`,
    params: {
      limit: limit,
      page: page,
      sort: sort,
    },
  };

  if (status) {
    config.params.status = status;
  }

  config.require_token = true;
  return queryService(config);
};

export const getOrderByID = (id) => {
  const config = {
    method: 'GET',
    route: `/customer/order/${id}`,
  };

  config.require_token = true;
  return queryService(config);
};

// not queryservice
export const trackOrder = (id, email) => {
  let url = '';
  let headers = {};
  if (email) {
    url = `${api}/track/order?order_number=${id}&email=${email}`;
    headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
  } else {
    url = `${api}/track/order?order_number=${id}`;
    headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${Cookie.getToken()}`,
    };
  }
  return queryCatch(
    fetch(url, {
      method: 'GET',
      headers: headers,
      // body: {limit}
    }),
  );
};

// CART MANAGEMENT

export const removeCartItems = (product_id, type, quote_id) => {
  const user = Cookie.getUser();
  const id = type === 'local' ? quote_id : user.quote_id;
  const config = {};
  // console.log(id)
  // debugger
  config.method = 'DELETE';
  config.route = `/remove_cart/${product_id}`;
  config.require_token = true;
  config.body = formurlencoded({ quote_id: id });
  return queryService(config);
};

// not queryservice
export const addItemToCart = (body, type, quote_id) => {
  const user = Cookie.getUser();
  // console.log(user)
  // console.log(user)
  const registeredBody = {
    ...body,
    quote_id: user ? user.quote_id : null,
  };

  const guestBody = quote_id
    ? {
        ...body,
        quote_id,
      }
    : {
        ...body,
      };
  const fetchBody =
    type === 'local'
      ? {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formurlencoded({ ...guestBody }),
        }
      : {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${Cookie.getToken()}`,
          },
          body: formurlencoded({ ...registeredBody }),
        };
  // console.log(fetchBody)
  return queryCatch(fetch(`${api}/cart/`, fetchBody));
};

// not queryservice
export const addMultipleToCart = (products, quote_id, token) => {
  // const user = Cookie.getUser()
  return queryCatch(
    fetch(`${api}/cart/items`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        quote_id,
        products,
      }),
    }),
  );
};

export const getCartItems = (quoteID) => {
  const user = Cookie.getUser();
  const quote_id = quoteID || user.quote_id;
  // console.log(user.quote_id)
  const config = {};
  config.method = 'GET';
  config.route = `/cart/${quote_id}`;
  config.require_token = true;
  return queryService(config);
};

// CATEGORY

export const getCategoryById = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/category/${id}`;
  return queryService(config);
};

// PROMOS

export const getPromosById = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/promos`;
  return queryService(config);
};

export const getPromoBlock = (is_online = 'both', sort_by = JSON.stringify([ 'order_number', 1 ])) => {
  const config = {};
  config.method = 'GET';
  config.route = `/promo/block`;
  config.params = {
    is_online,
    sort_by,
  };
  return queryService(config);
};

// PRODUCT

export const getAllProducts = ({ query, filter, limit, offset, orderBy, isSort }) => {
  const config = {
    method: 'GET',
    route: '/products',
    require_token: true,
    params: {
      with_stock: true,
      limit: limit,
      offset: offset,
      additional_attributes:
        '{"attributes" : ["category", "mhero_new_price", "status", "small_image_label", "special_price", "special_from_date", "special_to_date"]}',
      product_brand_id: `[${query}]`,
    },
  };
  switch (orderBy) {
    case '77':
      config.params.sort = `[{ "attribute_id": "${orderBy}", "backend_type": "decimal", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case '73':
      config.params.sort = `[{ "attribute_id": "${orderBy}", "backend_type": "varchar", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case '134':
      config.params.sort = `[{ "attribute_id": "${orderBy}", "backend_type": "varchar", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case 'product_brand':
      config.params.sort = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort
        ? true
        : false}}, {"attribute_id": "created_at", "backend_type": "decimal", "order": false}]`;
      break;
    case 'created_at':
      config.params.sort = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort ? false : true} }]`;
      break;
    default:
      break;
  }
  if (filter.price) {
    config.params.filter = `[{"attribute_code":"status","backend_type":"int","operator":"=","value":1},{"attribute_code":"mhero_internal_status","backend_type":"varchar","operator":"like","value":"posted"},{"attribute_code":"price","backend_type":"decimal","operator":"between","value":[${filter.price.map(
      (value) => `"${value}"`,
    )}]}]`;
  } else {
    config.params.filter = `[{"attribute_code":"status","backend_type":"int","operator":"=","value":1},{"attribute_code":"mhero_internal_status","backend_type":"varchar","operator":"like","value":"posted"}]`;
  }
  // console.log(config.params)
  // debugger
  return queryService(config);
};

// export const whatsNewProducts = (offset, limit) => {
//   const config= {}
//   config.route = `/whats/new/products`
//   config.require_token = true
//   config.params = {
//     whatsnew: true,
//     order: 'ASC',
//     page: offset,
//     limit,
//     // additional_attributes: '{"attributes" : ["status",  "mhero_new_price"]}',
//   }
//   config.method = 'GET'
//   return queryService(config)
// }

export const whatsNewProducts = (offset, limit, ids) => {
  const excluded_ids = `[${ids.reduce((acc, id, idx) => {
    if (!id) return acc; // ignore undefined
    return (acc = idx === 1 ? `${id}` : `${acc}, ${id}`); // go to index 1 as start
  }, '')}]`;
  // debugger
  const config = {
    method: 'GET',
    route: '/products',
    require_token: true,
    params: {
      whats_new: true,
      limit: limit || 12,
      offset: offset || 1,
      additional_attributes:
        '{"attributes" : ["category",  "mhero_new_price", "news_from_date", "news_to_date", "status", "special_price", "special_from_date", "special_to_date"]}',
      filter: `[
        {"attribute_code":"status","backend_type":"int","operator":"=","value":1},
        {"attribute_code":"mhero_internal_status","backend_type":"varchar","operator":"like","value":"posted"}
      ]`,
      excluded_ids,
    },
  };
  return queryService(config);
};

export const getSoldHomepage = () => {
  const attribute_groups_attributes = parseAttributeGroup(TileManager.getAttributeGroup());
  const config = {
    method: 'GET',
    route: '/homepage/recentlysold',
    params: {
      attribute_groups_attributes,
    },
  };
  return queryService(config);
};

export const getWhatsNew = ({ page, limit, excluded_ids: ids = [] }) => {
  const attribute_groups_attributes = parseAttributeGroup(TileManager.getAttributeGroup());
  const excluded_ids = `[${ids.reduce((acc, id, idx) => {
    if (!id) return acc; // ignore undefined
    return (acc = idx === 1 ? `${id}` : `${acc}, ${id}`); // go to index 1 as start
  }, '')}]`;
  const params = {
    excluded_ids,
    page,
    limit,
  };
  const config = {
    method: 'GET',
    route: '/homepage/whats_new',
    params: {
      attribute_groups_attributes,
      ...params,
    },
  };
  return queryService(config);
};

export const getProductFilters = ({
  query,
  filter = {},
  category_id,
  brand_id,
  excluded_ids,
  sales,
  promo_id,
  recently_sold,
}) => {
  let filter_query = defaultFilterQuery();
  if (filter.price) {
    filter_query = [
      ...filter_query,
      {
        attribute_code: 'price',
        backend_type: 'decimal',
        operator: 'between',
        value: filter.price.map((value) => `${value}`),
      },
    ];
  }
  if (recently_sold) {
    filter_query = [
      {
        attribute_code: 'mhero_internal_status',
        backend_type: 'varchar',
        operator: '=',
        value: 'sold',
      },
    ];
    if (filter.price) {
      filter_query = [
        ...filter_query,
        {
          attribute_code: 'price',
          backend_type: 'decimal',
          operator: 'between',
          value: filter.price.map((value) => `${value}`),
        },
      ];
    }
  }
  filter_query = `[${filter_query.map((item) => JSON.stringify(item))}]`;
  const config = {
    method: 'GET',
    route: '/products_filters/web/',
    params: {
      filter_query,
      search: query,
    },
  };

  if (category_id) {
    config.params.category_id = `[${category_id}]`;
  }

  if (brand_id) {
    config.params.product_brand_ids = `[${brand_id}]`;
  }

  if (filter.brands) {
    config.params.product_brand_ids = `[${filter.brands.map((brand) => `"${brand}"`)}]`;
  }

  if (filter.categories) {
    config.params.category_id = `[${filter.categories.map((category) => `"${category}"`)}]`;
  }

  if (filter.genders) {
    config.params.gender = `[${filter.genders.map((gender) => `"${gender}"`)}]`;
  }

  // config.params.with_stock = true;
  // On Sale
  if (sales) {
    config.params.sales = 1;
  }

  // promos
  if (promo_id) {
    config.params.promo_id = promo_id;
  }

  if (!recently_sold) {
    config.params.with_stock = true;
  }

  if (excluded_ids) {
    const ids = `[${excluded_ids.reduce((acc, id, idx) => {
      if (!id) return acc; // ignore undefined
      return (acc = idx === 1 ? `${id}` : `${acc}, ${id}`); // go to index 1 as start
    }, '')}]`;
    config.params.excluded_ids = ids;
  }
  return queryService(config);
};

export const getProductsRecentlySold = ({
  offset,
  limit,
  filter = {},
  orderBy,
  isSort,
  excluded_ids,
  quote_id,
  query,
  category_id,
  brand_id,
}) => {
  let filter_query = defaultFilterQuery();
  filter_query = [
    {
      attribute_code: 'mhero_internal_status',
      backend_type: 'varchar',
      operator: '=',
      value: 'sold',
    },
    {
      attribute_code: 'image',
      backend_type: 'varchar',
      operator: '!=',
      value: 'null',
    }
  ];
  if (filter.price) {
    filter_query = [
      ...filter_query,
      {
        attribute_code: 'price',
        backend_type: 'decimal',
        operator: 'between',
        value: filter.price.map((value) => `${value}`),
      },
    ];
  }
  filter_query = `[${filter_query.map((item) => JSON.stringify(item))}]`;

  const config = {
    method: 'GET',
    route: `/products_recently_sold`,
    params: {
      limit: limit || 12,
      page: offset || 1,
      filter_query,
      search: query,
      recently_sold: 1
    },
  };

  if (excluded_ids) {
    const ids = `[${excluded_ids.reduce((acc, id, idx) => {
      if (!id) return acc; // ignore undefined
      return (acc = idx === 1 ? `${id}` : `${acc}, ${id}`); // go to index 1 as start
    }, '')}]`;
    config.params.excluded_ids = ids;
  }

  if (category_id) {
    config.params.category_id = `[${category_id}]`;
  }

  if (brand_id) {
    config.params.product_brand_ids = `[${brand_id}]`;
  }

  if (filter.brands) {
    config.params.product_brand_ids = `[${filter.brands.map((brand) => `"${brand}"`)}]`;
  }

  if (filter.categories) {
    config.params.category_id = `[${filter.categories.map((category) => `"${category}"`)}]`;
  }

  if (filter.genders) {
    config.params.gender = `[${filter.genders.map((gender) => `"${gender}"`)}]`;
  }

  switch (orderBy) {
    case '77':
      config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "decimal", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case '73':
    case '134':
      config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "varchar", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case 'created_at':
      config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case 'product_brand':
      config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort
        ? true
        : false}}, {"attribute_id": "created_at", "backend_type": "decimal", "order": false}]`;
      break;
    default:
  }

  // handles guest || logged_in user
  const user = Cookie.getUser();
  if (user) {
    const { wishlist_id } = user;
    if (wishlist_id) config.params.wishlist_id = wishlist_id;
  }

  if (quote_id) config.params.quote_id = quote_id;

  return queryService(config);
};

export const getProductsByPromoID = ({
  id,
  offset,
  limit,
  filter = {},
  orderBy,
  isSort,
  excluded_ids,
  quote_id,
  query,
  category_id,
  brand_id,
}) => {
  let filter_query = defaultFilterQuery();
  if (filter.price) {
    filter_query = [
      ...filter_query,
      {
        attribute_code: 'price',
        backend_type: 'decimal',
        operator: 'between',
        value: filter.price.map((value) => `${value}`),
      },
    ];
  }
  filter_query = `[${filter_query.map((item) => JSON.stringify(item))}]`;

  const config = {
    method: 'GET',
    route: `/products_promo/${id}`,
    params: {
      limit: limit || 12,
      page: offset || 1,
      filter_query,
      search: query,
    },
  };

  if (excluded_ids) {
    const ids = `[${excluded_ids.reduce((acc, id, idx) => {
      if (!id) return acc; // ignore undefined
      return (acc = idx === 1 ? `${id}` : `${acc}, ${id}`); // go to index 1 as start
    }, '')}]`;
    config.params.excluded_ids = ids;
  }

  if (category_id) {
    config.params.category_id = `[${category_id}]`;
  }

  if (brand_id) {
    config.params.product_brand_ids = `[${brand_id}]`;
  }

  if (filter.brands) {
    config.params.product_brand_ids = `[${filter.brands.map((brand) => `"${brand}"`)}]`;
  }

  if (filter.categories) {
    config.params.category_id = `[${filter.categories.map((category) => `"${category}"`)}]`;
  }

  if (filter.genders) {
    config.params.gender = `[${filter.genders.map((gender) => `"${gender}"`)}]`;
  }

  switch (orderBy) {
    case '77':
      config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "decimal", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case '73':
    case '134':
      config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "varchar", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case 'created_at':
      config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case 'product_brand':
      config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort
        ? true
        : false}}, {"attribute_id": "created_at", "backend_type": "decimal", "order": false}]`;
      break;
    default:
  }

  // handles guest || logged_in user
  const user = Cookie.getUser();
  if (user) {
    const { wishlist_id } = user;
    if (wishlist_id) config.params.wishlist_id = wishlist_id;
  }

  if (quote_id) config.params.quote_id = quote_id;

  return queryService(config);
};

export const getProductsOnSale = ({
  offset,
  limit,
  filter = {},
  orderBy,
  isSort,
  excluded_ids,
  quote_id,
  query,
  category_id,
  brand_id,
}) => {
  let filter_query = defaultFilterQuery();
  if (filter.price) {
    filter_query = [
      ...filter_query,
      {
        attribute_code: 'price',
        backend_type: 'decimal',
        operator: 'between',
        value: filter.price.map((value) => `${value}`),
      },
    ];
  }
  filter_query = `[${filter_query.map((item) => JSON.stringify(item))}]`;

  const config = {
    method: 'GET',
    route: '/products_onsale',
    params: {
      limit: limit || 12,
      page: offset || 1,
      filter_query,
      search: query,
    },
  };

  if (excluded_ids) {
    const ids = `[${excluded_ids.reduce((acc, id, idx) => {
      if (!id) return acc; // ignore undefined
      return (acc = idx === 1 ? `${id}` : `${acc}, ${id}`); // go to index 1 as start
    }, '')}]`;
    config.params.excluded_ids = ids;
  }

  if (category_id) {
    config.params.category_id = `[${category_id}]`;
  }

  if (brand_id) {
    config.params.product_brand_ids = `[${brand_id}]`;
  }

  if (filter.brands) {
    config.params.product_brand_ids = `[${filter.brands.map((brand) => `"${brand}"`)}]`;
  }

  if (filter.categories) {
    config.params.category_id = `[${filter.categories.map((category) => `"${category}"`)}]`;
  }

  if (filter.genders) {
    config.params.gender = `[${filter.genders.map((gender) => `"${gender}"`)}]`;
  }

  switch (orderBy) {
    case '77':
      config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "decimal", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case '73':
    case '134':
      config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "varchar", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case 'created_at':
      config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case 'product_brand':
      config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort
        ? true
        : false}}, {"attribute_id": "created_at", "backend_type": "decimal", "order": false}]`;
      break;
    default:
  }

  // handles guest || logged_in user
  const user = Cookie.getUser();
  if (user) {
    const { wishlist_id } = user;
    if (wishlist_id) config.params.wishlist_id = wishlist_id;
  }

  if (quote_id) config.params.quote_id = quote_id;

  return queryService(config);
};

export const getAllProductsOnly = ({
  query,
  filter = {},
  category_id,
  brand_id,
  limit,
  offset,
  quote_id,
  orderBy,
  isSort,
  whats_new,
  excluded_ids,
  sales,
  promo_id,
  recently_sold,
}) => {
  try {
    const attribute_groups_attributes = parseAttributeGroup(TileManager.getAttributeGroup());
    let filter_query = defaultFilterQuery();
    if (filter.price) {
      filter_query = [
        ...filter_query,
        {
          attribute_code: 'price',
          backend_type: 'decimal',
          operator: 'between',
          value: filter.price.map((value) => `${value}`),
        },
      ];
    }
    if (recently_sold) {
      filter_query = [
        {
          attribute_code: 'mhero_internal_status',
          backend_type: 'varchar',
          operator: '=',
          value: 'sold',
        },
      ];
      if (filter.price) {
        filter_query = [
          ...filter_query,
          {
            attribute_code: 'price',
            backend_type: 'decimal',
            operator: 'between',
            value: filter.price.map((value) => `${value}`),
          },
        ];
      }
    }
    filter_query = `[${filter_query.map((item) => JSON.stringify(item))}]`;
    const config = {
      method: 'GET',
      route: '/products_only',
      require_token: true,
      params: {
        limit: limit || 12,
        page: offset || 1,
        with_stock: true,
        // attribute_groups_attributes,
        filter_query,
        search: query,
      },
    };
    if (category_id) {
      config.params.category_id = `[${category_id}]`;
    }
    // // brand checkbox selection
    if (brand_id) {
      config.params.product_brand_ids = `[${brand_id}]`;
    }

    if (filter.brands) {
      config.params.product_brand_ids = `[${filter.brands.map((brand) => `"${brand}"`)}]`;
    }

    if (filter.categories) {
      config.params.category_id = `[${filter.categories.map((category) => `"${category}"`)}]`;
    }

    if (filter.genders) {
      config.params.gender = `[${filter.genders.map((gender) => `"${gender}"`)}]`;
    }

    // Whats new
    if (whats_new) {
      config.params.whats_new = true;
    }

    // Recently Sold
    if (recently_sold) {
      const { with_stock, ...rest } = config.params;
      config.params = rest;
      config.params.recently_sold = 1;
    }

    // On Sale
    if (sales) {
      config.params.sales = 1;
    }

    // promos
    if (promo_id) {
      config.params.promo_id = promo_id;
    }

    if (excluded_ids) {
      const ids = `[${excluded_ids.reduce((acc, id, idx) => {
        if (!id) return acc; // ignore undefined
        return (acc = idx === 1 ? `${id}` : `${acc}, ${id}`); // go to index 1 as start
      }, '')}]`;
      config.params.excluded_ids = ids;
    }

    // Sorting
    switch (orderBy) {
      case '77':
        config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "decimal", "order": ${isSort
          ? true
          : false} }]`;
        break;
      case '73':
      case '134':
        config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "varchar", "order": ${isSort
          ? true
          : false} }]`;
        break;
      case 'created_at':
        config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort
          ? true
          : false} }]`;
        break;
      case 'product_brand':
        config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort
          ? true
          : false}}, {"attribute_id": "created_at", "backend_type": "decimal", "order": false}]`;
        break;
      default:
    }

    // handles guest || logged_in user
    const user = Cookie.getUser();
    if (user) {
      const { wishlist_id } = user;
      if (wishlist_id) config.params.wishlist_id = wishlist_id;
    }

    if (quote_id) config.params.quote_id = quote_id;

    return queryService(config);
  } catch (error) {
    console.error(error);
  }
};

export const getProductsV2 = ({
  query,
  filter = {},
  category_id,
  brand_id,
  limit,
  offset,
  quote_id,
  orderBy,
  isSort,
  whats_new,
  excluded_ids,
  sales,
  promo_id,
  recently_sold,
}) => {
  try {
    const attribute_groups_attributes = parseAttributeGroup(TileManager.getAttributeGroup());
    let filter_query = defaultFilterQuery();
    if (filter.price) {
      filter_query = [
        ...filter_query,
        {
          attribute_code: 'price',
          backend_type: 'decimal',
          operator: 'between',
          value: filter.price.map((value) => `${value}`),
        },
      ];
    }
    if (recently_sold) {
      filter_query = [
        {
          attribute_code: 'mhero_internal_status',
          backend_type: 'varchar',
          operator: '=',
          value: 'sold',
        }
      ];
      if (filter.price) {
        filter_query = [
          ...filter_query,
          {
            attribute_code: 'price',
            backend_type: 'decimal',
            operator: 'between',
            value: filter.price.map((value) => `${value}`),
          },
        ];
      }
    }
    filter_query = `[${filter_query.map((item) => JSON.stringify(item))}]`;
    const config = {
      method: 'GET',
      route: '/products_v2_1',
      require_token: true,
      params: {
        limit: limit || 12,
        page: offset || 1,
        with_stock: true,
        // attribute_groups_attributes,
        filter_query,
        search: query,
      },
    };
    if (category_id) {
      config.params.category_id = `[${category_id}]`;
    }
    // // brand checkbox selection
    if (brand_id) {
      config.params.product_brand_ids = `[${brand_id}]`;
    }

    if (filter.brands) {
      config.params.product_brand_ids = `[${filter.brands.map((brand) => `"${brand}"`)}]`;
    }

    if (filter.categories) {
      config.params.category_id = `[${filter.categories.map((category) => `"${category}"`)}]`;
    }

    if (filter.genders) {
      config.params.gender = `[${filter.genders.map((gender) => `"${gender}"`)}]`;
    }

    // Whats new
    if (whats_new) {
      config.params.whats_new = true;
    }

    // Recently Sold
    if (recently_sold) {
      const { with_stock, ...rest } = config.params;
      config.params = rest;
      config.params.recently_sold = 1;
    }

    // On Sale
    if (sales) {
      config.params.sales = 1;
    }

    // promos
    if (promo_id) {
      config.params.promo_id = promo_id;
    }

    if (excluded_ids) {
      const ids = `[${excluded_ids.reduce((acc, id, idx) => {
        if (!id) return acc; // ignore undefined
        return (acc = idx === 1 ? `${id}` : `${acc}, ${id}`); // go to index 1 as start
      }, '')}]`;
      config.params.excluded_ids = ids;
    }

    // Sorting
    switch (orderBy) {
      case '77':
        config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "decimal", "order": ${isSort
          ? true
          : false} }]`;
        break;
      case '73':
      case '134':
        config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "varchar", "order": ${isSort
          ? true
          : false} }]`;
        break;
      case 'created_at':
        config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort
          ? true
          : false} }]`;
        break;
      case 'product_brand':
        config.params.sort_query = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort
          ? true
          : false}}, {"attribute_id": "created_at", "backend_type": "decimal", "order": false}]`;
        break;
      default:
    }

    // handles guest || logged_in user
    const user = Cookie.getUser();
    if (user) {
      const { wishlist_id } = user;
      if (wishlist_id) config.params.wishlist_id = wishlist_id;
    }

    if (quote_id) config.params.quote_id = quote_id;

    return queryService(config);
  } catch (error) {
    console.error(error);
  }
};

export const getProducts = ({ query, filter, limit, offset, orderBy, isSort }) => {
  const config = {
    method: 'GET',
    route: '/products',
    require_token: true,
    params: {
      limit: limit || 12,
      offset: offset || 1,
      additional_attributes:
        '{"attributes" : ["category",  "mhero_new_price", "status", "small_image_label", "special_price", "special_from_date", "special_to_date"]}',
      search: query,
    },
  };

  switch (orderBy) {
    case '77':
      config.params.sort = `[{ "attribute_id": "${orderBy}", "backend_type": "decimal", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case '73':
      config.params.sort = `[{ "attribute_id": "${orderBy}", "backend_type": "varchar", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case '134':
      config.params.sort = `[{ "attribute_id": "${orderBy}", "backend_type": "varchar", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case 'created_at':
      config.params.sort = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort ? false : true} }]`;
      break;
    case 'product_brand':
      config.params.sort = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort
        ? true
        : false}}, {"attribute_id": "created_at", "backend_type": "decimal", "order": false}]`;
      break;
    default:
      break;
  }

  if (filter.brands) {
    config.params.product_brand_id = `[${filter.brands.map((brand) => `"${brand}"`)}]`;
  }

  config.params.filter = `[
    {"attribute_code":"status","backend_type":"int","operator":"=","value":1},
    {"attribute_code":"mhero_internal_status","backend_type":"varchar","operator":"like","value":"posted"}
  ]`;

  if (filter.price) {
    config.params.filter = `[
      {"attribute_code":"status","backend_type":"int","operator":"=","value":1},
      {"attribute_code":"mhero_internal_status","backend_type":"varchar","operator":"like","value":"posted"},
      {"attribute_code":"price","backend_type":"decimal","operator":"between","value":[${filter.price.map(
        (value) => `"${value}"`,
      )}]}
    ]`;
  }

  return queryService(config);
};

export const getProductsByCategory = ({ query, filter, limit, offset, orderBy, isSort }) => {
  const config = {
    require_token: true,
  };
  config.method = 'GET';
  config.route = `/products/category/${query}`;

  config.params = {
    with_stock: true,
    limit: limit || 12,
    offset: offset || 1,
    additional_attributes:
      '{"attributes" : ["category",  "mhero_new_price", "status", "small_image_label", "special_price", "special_from_date", "special_to_date"]}',
    flag: 1,
  };
  switch (orderBy) {
    case '77':
      config.params.sort = `[{ "attribute_id": "${orderBy}", "backend_type": "decimal", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case '73':
      config.params.sort = `[{ "attribute_id": "${orderBy}", "backend_type": "varchar", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case '134':
      config.params.sort = `[{ "attribute_id": "${orderBy}", "backend_type": "varchar", "order": ${isSort
        ? true
        : false} }]`;
      break;
    case 'product_brand':
      config.params.sort = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort
        ? true
        : false}}, {"attribute_id": "created_at", "backend_type": "decimal", "order": false}]`;
      break;
    case 'created_at':
      config.params.sort = `[{ "attribute_id": "${orderBy}", "backend_type": "", "order": ${isSort ? false : true} }]`;
      break;
    default:
      break;
  }

  if (filter.brands) {
    config.params.product_brand_id = `[${filter.brands.map((brand) => `"${brand}"`)}]`;
  }

  config.params.filter = `[
    {"attribute_code":"status","backend_type":"int","operator":"=","value":1},
    {"attribute_code":"mhero_internal_status","backend_type":"varchar","operator":"like","value":"posted"}
  ]`;

  if (filter.price) {
    config.params.filter = `[
      {"attribute_code":"status","backend_type":"int","operator":"=","value":1},
      {"attribute_code":"mhero_internal_status","backend_type":"varchar","operator":"like","value":"posted"},
      {"attribute_code":"price","backend_type":"decimal","operator":"between","value":[${filter.price.map(
        (value) => `"${value}"`,
      )}]}
    ]`;
  }
  // console.log(config.params)
  return queryService(config);
};

// for product detail

export const getProductByID = ({ id, params }) => {
  // console.log(params)
  const config = {};
  config.method = 'GET';
  config.route = `/products/${id}`;
  // config.params = params
  config.require_token = true;
  config.params = {
    ...params,
    additional_attributes:
      '{"attributes" : [ "category","gallery","mhero_internal_status","mhero_item_condition","mhero_rating","mhero_new_price","status","news_from_date","news_to_date","meta_title","meta_keyword","meta_description", "special_price", "special_from_date", "special_to_date"]}',
  };
  return queryService(config);
};

export const getProductByID_v2 = ({ id, params }) => {
  // console.log(params)
  const user = Cookie.getUser();
  const config = {};
  config.method = 'GET';
  config.route = `/product_v2_1/${id}`;
  config.params = {
    wishlist_id: user ? user.wishlist_id : null,
  };
  config.require_token = true;
  // config.params = {
  //   ...params,
  //   additional_attributes: '{"attributes" : [ "category","gallery","mhero_internal_status","mhero_item_condition","mhero_rating","mhero_new_price","status","news_from_date","news_to_date","meta_title","meta_keyword","meta_description", "special_price", "special_from_date", "special_to_date"]}'
  // }
  return queryService(config);
};

// BRAND

export const getAllBrandByCategoryID = (id) => {
  const config = {
    method: 'GET',
    route: `/brands/category/${id}`,
  };
  return queryService(config);
};

export const getAllBrands = () => {
  const config = {
    method: 'GET',
    route: '/brands',
    params: {
      //   search: '',
      //   sort_name: '',
      //   featured: '',
      //   offset: 0,
      limit: 99999,
    },
  };
  return queryService(config);
};

// /priority/brands?search&filter&offset=0&limit=9999

export const getAllPriorityBrands = () => {
  const config = {
    method: 'GET',
    route: '/priority/brands?search&filter&offset=0&limit=9999',
    params: {
      limit: 99999,
    },
  };
  return queryService(config);
};

export const getBrandById = (id) => {
  const config = {
    method: 'GET',
    route: `/brands/${id}`,
  };
  return queryService(config);
};


export const deactivateAccount = (mobToken = '') => {

  const config = {};
  // config.mobToken = mobToken;

  if(mobToken !== ''){
    config.mobToken = mobToken
  }
  config.method = 'PUT';
  config.route = '/customer/deactivate-account';
  config.require_token = true;
  return queryService(config);


}

export const generateDeleteRequest = (body, mobToken = '') => {
  console.log(body)
  const config = {};
  config.body = formurlencoded(body);
  if(mobToken !== ''){
    config.mobToken = mobToken
  }

  config.method = 'POST';
  config.route = '/sys/add-request-queue';
  config.require_token = true;
  return queryService(config);
};

export const generateTicket = (body) => {
  const config = {};
  config.body = formurlencoded(body);
  config.method = 'POST';
  config.route = '/tickets';
  config.require_token = true;
  return queryService(config);
};

// CUSTOMER CREDENTIALS

export const postForgotPassword = (body) => {
  const config = {};
  config.body = formurlencoded(body);
  config.method = 'POST';
  config.route = '/customer/forgot-password';
  return queryService(config);
};

export const postResetPassword = (body) => {
  const config = {};
  config.body = formurlencoded(body);
  config.method = 'POST';
  config.route = '/customer/reset-password';
  return queryService(config);
};

// ADDRESS

export const getProvinces = () => {
  const config = {
    method: 'GET',
    route: '/address/provinces',
    require_token: true,
  };
  return queryService(config);
};

export const getMunicipalitiesByProvinceCode = ({ province_code }) => {
  const config = {
    method: 'GET',
    route: `/address/municipality/${province_code}`,
    require_token: true,
  };
  return queryService(config);
};

export const getBarangayByMunCode = ({ municipality_code }) => {
  const config = {
    method: 'GET',
    route: `/address/barangay/${municipality_code}`,
    require_token: true,
  };
  return queryService(config);
};

// RATE

export const getRate = (region_id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/rate`;
  config.params = {
    region_id,
  };
  return queryService(config);
};

// PAYMENT METHOD

export const getPaymentMethod = (region_id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/checkout/payment_method`;
  config.params = { region_id };
  config.require_token = true;
  return queryService(config);
};

export const postPaymentMethod = (payment_method_id, type, quote_id) => {
  const user = Cookie.getUser();
  const id = type === 'local' ? quote_id : user.quote_id;

  const config = {};
  config.method = 'POST';
  config.route = `/checkout/payment_method`;
  config.body = formurlencoded({ payment_method_id, quote_id: id });
  config.require_token = true;
  return queryService(config);
};

// CHECKOUT

// not queryservice
export const itemsAvailability = (body) => {
  return queryCatch(
    fetch(`${api}/checkout/availability`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...body }),
    }),
  );
};

export const revertCoupon = (quote_id) => {
  const user = Cookie.getUser();
  const id = quote_id ? quote_id : user.quote_id;
  const config = {};
  config.method = 'PUT';
  config.route = `/order_revert_coupon`;
  config.body = formurlencoded({ quote_id: id });
  return queryService(config);
};

// Promotion Voucher

export const PromotionVoucher = ({ voucher_code, quote_id, flag }) => {
  const hasAccess = Cookie.hasAccess();
  const user = Cookie.getUser();

  const body = flag
    ? {
        voucher_code,
        quote_id: quote_id || user.quote_id,
        flag: 1,
      }
    : {
        voucher_code,
        quote_id: quote_id || user.quote_id,
      };

  const headers = hasAccess
    ? {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${Cookie.getToken()}`,
      }
    : {
        'Content-Type': 'application/x-www-form-urlencoded',
      };

  return queryCatch(
    fetch(`${api}/promotions_apply_voucher`, {
      method: 'POST',
      headers,
      body: formurlencoded(body),
    }),
  );
};

export const PromotionImplicit = (quote_id) => {
  const hasAccess = Cookie.hasAccess();
  // const user = Cookie.getUser()

  // debugger

  const body = {
    quote_id,
  };

  const headers = hasAccess
    ? {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${Cookie.getToken()}`,
      }
    : {
        'Content-Type': 'application/x-www-form-urlencoded',
      };

  return queryCatch(
    fetch(`${api}/promotions_v2`, {
      method: 'POST',
      headers,
      body: formurlencoded(body),
    }),
  );
};

export const ApplyPromotion = (body) => {
  const hasAccess = Cookie.hasAccess();
  // const user = Cookie.getUser()

  // const body = flag ? {
  //   voucher_code,
  //   quote_id: quote_id || user.quote_id,
  //   flag: 1
  // } : {
  //   voucher_code,
  //   quote_id: quote_id || user.quote_id,
  // }

  const headers = hasAccess
    ? {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${Cookie.getToken()}`,
      }
    : {
        'Content-Type': 'application/x-www-form-urlencoded',
      };

  return queryCatch(
    fetch(`${api}/promotions_v2_apply`, {
      method: 'POST',
      headers,
      body: formurlencoded(body),
    }),
  );
};

// not queryservice
export const verifyApplyCoupon = ({ coupon_code, apply_to_cart, quote_id }) => {
  // console.log(quote_id)
  const hasAccess = Cookie.hasAccess();
  const user = Cookie.getUser();
  // console.log(user)
  const body = !quote_id
    ? {
        coupon_code,
        apply_to_cart,
        quote_id: user.quote_id,
      }
    : {
        coupon_code,
        apply_to_cart,
        quote_id,
      };
  const headers = hasAccess
    ? {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${Cookie.getToken()}`,
      }
    : {
        'Content-Type': 'application/x-www-form-urlencoded',
      };
  return queryCatch(
    fetch(`${api}/coupons/apply`, {
      method: 'POST',
      headers,
      body: formurlencoded(body),
    }),
  );
};

// not queryservice
export const getOrderSummary = (quote_id, email = '') => {
  const hasAccess = Cookie.hasAccess();
  const headers = hasAccess
    ? {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${Cookie.getToken()}`,
      }
    : {
        'Content-Type': 'application/x-www-form-urlencoded',
      };

  return queryCatch(
    fetch(`${api}/order_summary/${quote_id}?email=${email}`, {
      method: 'GET',
      headers,
    }),
  );
};

export const cancelOrder = (order_id) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/customer/order/status/${order_id}`;
  config.require_token = true;
  return queryService(config);
};

export const cancelOrderWithStatus = (order_id) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/customer/order/status/${order_id}`;
  return queryService(config);
};

// not queryservice
export const paymentSubmit = (type, quote_id, discount_amount = null) => {
  const user = Cookie.getUser();

  // console.log(quote_id)
  const registeredBody = {
    quote_id: user ? user.quote_id : null,
    cart_discount: discount_amount,
    order_platform: 'web app',
  };

  const guestBody = {
    quote_id: quote_id || null,
    cart_discount: discount_amount,
    order_platform: 'web app',
  };

  const fetchBody =
    type === 'local'
      ? {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formurlencoded({ ...guestBody }),
        }
      : {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${Cookie.getToken()}`,
          },
          body: formurlencoded({ ...registeredBody }),
        };
  // debugger
  return queryCatch(fetch(`${api}/payment_submit?discount_amount=${discount_amount}`, fetchBody));
};

// not queryservice
export const updateCheckoutAddress = (body, type, quote_id) => {
  const user = Cookie.getUser();
  const hasAccess = Cookie.hasAccess();
  const id = type === 'local' ? quote_id : user.quote_id;
  const headers = hasAccess
    ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookie.getToken()}`,
      }
    : {
        'Content-Type': 'application/json',
      };
  // console.log(body)
  return queryCatch(
    fetch(`${api}/checkout`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ ...body, quote_id: id }),
    }),
  );
};

export const remove = (id) => {
  const config = {
    method: 'DELETE',
    route: `/contacts/${id}`,
  };
  return queryService(config);
};

export const create = (body) => {
  const config = {};
  config.body = body;
  config.method = 'POST';
  config.route = '/contacts';
  return queryService(config);
};

// Banner

export const getBanners = () => {
  const config = {
    method: 'GET',
    route: '/web-banners',
    params: {
      sort: 'redirect_link',
      is_enabled: true,
    },
  };
  return queryService(config);
};

// Static Pages

export const getAllStaticPage = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/static_pages`;
  return queryService(config);
};

export const getStaticPage = async (key) => {
  const config = {};
  config.method = 'GET';
  config.route = `/static_page`;
  config.params = {
    request_path: key,
  };

  try {
    await queryService(config);
    return queryService(config);
  } catch (err) {
    config.params = {
      url_key: key,
    };
    return queryService(config);
  }
};

// feed
export const getXMLProductFeed = ({ params }) => {
  const config = {};
  config.method = 'GET';
  config.route = `/products_xml`;
  config.params = params;
  return queryService(config);
};

// checking if under maintenance

export const checkMaintenance = () => {
  const config = {};
  config.method = 'GET';
  config.route = '/maintenance/check';
  return queryService(config);
};

// Product group
export const getProductGroup = ({ route }) => {
  let headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  return queryCatch(
    fetch(`${api}${route}`, {
      method: 'GET',
      headers: headers,
    }),
  );
};

// check latest request path

export const checkLatestPath = (params) => {
  const config = {};
  config.method = 'GET';
  config.route = '/check_path';
  config.params = params;
  return queryService(config);
};

// for request that dont use queryService function
function queryCatch (request){
  return request
    .then((res) => {
      if (res.ok) {
        const result = res ? res.json() : {};
        // maintenanceCatch(result)
        return result;
      } else {
        const json = res.json();
        throw json;
      }
    })
    .catch((err = {}) => {
      if (err.then)
        return err.then(function (data){
          const errors = [
            {
              data,
            },
          ];
          throw errors;
        });
      else {
        // showMaintenance()
        const errors = [
          {
            data: {
              code: 'ZERO_RES',
              message: 'Unable to connect to server.',
              context: 'Please check your internet connection.',
            },
          },
        ];
        throw errors;
      }
    });
}

// Sales Dashboard
export const getSalesDashboard = () => {
  const config = {};
  config.method = 'GET';
  config.route = '/sales/dashboard';
  return queryService(config);
};

//Waitlist
export const addToWaitlist = (body) => {
  const user = Cookie.getUser();

  const fetchBody = !user
    ? {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formurlencoded({ ...body }),
      }
    : {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${Cookie.getToken()}`,
        },
        body: formurlencoded({ ...body }),
      };
  // console.log(fetchBody)
  return queryCatch(fetch(`${api}/waitlist`, fetchBody));
};

// function maintenanceCatch(request){
//   // console.log(request.then)
//   request.then(res => {
//     // console.log(res)
//     if(res.under_maintenance){
//       showMaintenance()
//     }
//   })
// }

// UPLOAD IMAGE

export const uploadImage = (body, id, type) => {
  let content_type = type ? `content_type=${type}` : '';

  const fd = new FormData();
  fd.append('file', body);
  return fetch(`${api}/storage/upload/${id}?${content_type}`, {
    method: 'POST',
    headers: {},
    body: fd,
  }).then((res) => {
    return res.json();
  });
};

function queryService ({ route, method, body, params, cache = false, require_token = false, customHeader, mobToken = false}){
  const mainHeaders = customHeader ? customHeader : headers;

  let actual_header = ''

      if(mobToken !== false){

        actual_header =
        require_token
          ? {
              ...mainHeaders,
              Authorization: `Bearer ${mobToken}`,
            }
          : mainHeaders;

      }else{
        actual_header =
        require_token && Cookie.getToken() !== null
          ? {
              ...mainHeaders,
              Authorization: `Bearer ${Cookie.getToken()}`,
            }
          : mainHeaders;
      }

  const actual_body = customHeader ? JSON.stringify(body) : body;
  // params.version = new Date().getTime();
  const serializeParams = params ? serializeUrl(params) : '';
  const t = !cache ? `?t=${new Date().getTime()}` : '?';

  // console.log(actual_header, "mimaaaaaaaaaaa")

  return fetch(
    `${api}${route}${t}${serializeParams}`,
    {
      method,
      headers: actual_header,
      body: actual_body,
    },
    5,
  )
    .then((res) => {
      if (res.ok) {
        const result = res ? res.json() : {};
        return result;
      } else {
        const json = res.json();
        throw json;
      }
    })
    .catch((err = {}) => {
      if (err.then)
        return err.then(function (data){
          let errors = [
            {
              data,
            },
          ];
          if (errors[0]) {
            if (errors[0].data.error.message.toLowerCase() === 'invalid token') {
              Cookie.removeUser();
              errors = [
                {
                  data: {
                    error: {
                      ...data.error,
                      message: 'Your session has expired. Please login again.',
                    },
                  },
                },
              ];
            }
          }
          throw errors;
        });
      else {
        // showMaintenance()
        const errors = {
          data: {
            code: 'ZERO_RES',
            message: 'Unable to connect to server.',
            context: 'Please check your internet connection.',
          },
        };
        throw errors;
      }
    });
}

function serializeUrl (obj, prefix){
  function start (){
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p];
        if (v !== '' && typeof v !== 'undefined')
          str.push(
            v !== null && typeof v === 'object'
              ? serializeUrl(v, k)
              : encodeURIComponent(k) + '=' + encodeURIComponent(v),
          );
      }
    }
    return str.join('&');
  }

  const start_value = start();
  return '&' + start_value;
}
