// This must be the first line in src/index.js
import "react-app-polyfill/ie11";
import "@babel/polyfill";
import objectFitImages from "object-fit-images";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "components/App";
import { unregister } from "registerServiceWorker";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Store } from "./Store";

import "bootstrap/dist/css/bootstrap.min.css";
import "rc-pagination/assets/index.css";
import "index.css";

import { Analytics } from "./utils/analytics";
import { Firebase } from "./utils/firebase";

Analytics.init();
Firebase.init();

const store = Store.state;
const persistor = Store.persistor(store);

// Start object-fit
objectFitImages();

//Polyfill Carousel here
(function() {
  if (typeof window.CustomEvent === "function") return false;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

unregister();
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
// serviceWorker();

export const Persistor = {
  state: persistor
};
