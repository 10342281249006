import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { getAPIPaynamics} from '../utils/API'
import Loader from './Loader'
import warning from '../assets/warning.svg'
import * as API from '../utils/API'

class Paynamics extends Component{
    constructor(props){
        super(props)
        this.state = {
            paymentSignature: '',
            isLoading: false,
            err: false,
            errMessage: '',
            // err: true,
            // errMessage: 'Something went wrong. Please try again.'
        }
        // this.paynamicsSubmit = this.paynamicsSubmit.bind(this)
        this.submitPaynamics = this.submitPaynamics.bind(this)
        this.placeOrder = this.placeOrder.bind(this)
    }

    submitPaynamics(paymentSignature){
        this.setState({
            paymentSignature
        }, ()=>{
            setTimeout(() => {
                this.setState({
                    isLoading: false
                })
                window.location.href = paymentSignature;
                // document.getElementById('paynamics-form').submit()
            }, 300);
        })
    }


    async placeOrder(quote_id){
        this.setState({
            isLoading: true,
            err: false,
        })
        try {
            const checkout = await API.paymentSubmit('local', quote_id)
            if(checkout.error){
                const { error : { message } } = checkout
                this.setState({
                    isLoading: false,
                    err: true,
                    errMessage: `${message}`
                })
            } else{
                const { data: {payment_type} } = checkout
                switch(payment_type){
                    // case "paymaya":{
                    //     window.location.replace(checkout.data.checkout_data.value)
                    //     break;
                    // }
                    // case "cod":{
                    //     const user = Cookie.getUser()
                    //     const quote_id = hasAccess ? user.quote_id : this.props.localCart.quote_id
                    //     this.props.history.push(`/ordersuccess/${quote_id}`)
                    //     break;
                    // }
                    // case "coins.ph":{
                    //     const user = Cookie.getUser()
                    //     const quote_id = hasAccess ? user.quote_id : this.props.localCart.quote_id
                    //     this.props.history.push(`/ordersuccess/${quote_id}`)
                    //     break;
                    // }
                    case "paynamics":{
                        this.submitPaynamics(checkout.data.checkout_data.value)
                        break;
                    }
                    default: {
                        // Should add support for unsupported payment method
                        break;
                    }
                }
            }
        } catch(err){
            this.setState({
                isLoading: false,
                err: true,
                errMessage: `${err}`
                // errMessage: 'Something went wrong. Please try again.'
            })
        }
    }

    componentDidMount(){
        const { match:{params: {quoteID}} } = this.props
        this.placeOrder(quoteID)
    }

    render(){
        const { paymentSignature, isLoading, err, errMessage } = this.state
        // const paynamicsAPI = getAPIPaynamics()
        return(
            <Fragment>
                <Loader isLoading={isLoading} />
                {
                    err &&
                    <div
                        className="d-flex "
                        style={{height: '100vh'}}>
                        <div className="m-auto text-center d-flex flex-column">
                            <img alt="warning" className="mx-auto mb-3" src={warning} width="30" height="30"/>
                            <span className="small">{errMessage}</span>
                        </div>
                    </div>
                }
                <form
                    id="paynamics-form"
                    name="form1"
                    method="post"
                    hidden
                    action={paymentSignature}>
                    <input type="text" name="paymentrequest" id="paymentrequest" value={paymentSignature} />
                    <input type="submit" />
                </form>
            </Fragment>
        )
    }
}

export default withRouter(Paynamics)