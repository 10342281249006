import { combineReducers } from 'redux';

import {
  POPULATE_STATIC_LINKS,
  POPULATE_CART,
  CLEAR_CART,
  PURGE_ALL,
  CHANGE_COLOR,
  TOGGLE_OVERLAY,
  TOGGLE_SEARCH,
  TOGGLE_LOADING,
  TOGGLE_MOBILESEARCH,
  TOGGLE_MOBILEFILTER,
  EXPECT_MOBILESEARCH,
  UI_SEARCH_QUERY,
  POPULATE_CATEGORY,
  TOGGLE_CART_LOADING,
  POPULATE_GUEST_CART,
  CART_ADD_LOCAL,
  CART_REMOVE_LOCAL,
  CART_ADD,
  CART_REMOVE,
  ALERT_DID_FORGOT_PASSWORD,
  ALERT_DID_TOGGLE_ALERT,
  DIALOG_TOGGLE,
  SET_AUTH,
  SET_SHIPPING_INFO,
  MARK_UNAVAILABLE,
  MARK_UNAVAILABLE_LOCAL,
  SET_PAYMENT_METHOD,
  RESET_CHECKOUT,
  CLEAR_LOCAL_CART,
  CLEAR_LOCAL_QUOTE,
  UPDATE_VOUCHER_BREAKDOWN,
  REMOVE_VOUCHER,
  REMOVE_BREAKDOWN,
  VOUCHER_SHOW_ERROR,
  VOUCHER_REMOVE_ERROR,
  BRANDS_POPULATE_LIST,
  PROMOS_POPULATE_LIST,
  // STATIC PAGE
  STATIC_SET_PAGE,
  CLEAR_CART_FROM_PAYNAMICS,
} from '../actions';

const initialStaticPages = {
  pages: {},
};

function staticPages (state = initialStaticPages, action){
  const { type } = action;
  switch (type) {
    case STATIC_SET_PAGE:
      const { page, url_key } = action;
      return {
        ...state,
        pages: {
          ...state.pages,
          [url_key]: page,
        },
      };
    default:
      return state;
  }
}

const intialStaticLinks = {
  static_pages: null,
};

function staticLink (state = intialStaticLinks, action){
  const { type, links } = action;

  switch (type) {
    case POPULATE_STATIC_LINKS: {
      return {
        ...state,
        static_pages: {
          ...links,
        },
      };
    }
    default:
      return state;
  }
}

const initialCategoryState = {
  categories: null,
};

function category (state = initialCategoryState, action){
  const { type, categories } = action;
  switch (type) {
    case POPULATE_CATEGORY: {
      return {
        ...state,
        categories,
      };
    }

    default:
      return state;
  }
}

const initialUIState = {
  query: '',
  method: undefined,
  isSearchOpen: false,
  isSearchLoading: false,
  showOverlay: false,
  showMobileSearch: false,
  showMobileFilter: false,
  shouldFocusMobileSearch: false,
};

function ui (state = initialUIState, action){
  const { type } = action;
  switch (type) {
    case TOGGLE_OVERLAY: {
      return {
        ...state,
        showOverlay: !state.showOverlay,
      };
    }
    case TOGGLE_LOADING: {
      const { flag } = action;
      return {
        ...state,
        isSearchLoading: flag,
      };
    }
    case TOGGLE_SEARCH: {
      const { flag } = action;
      return {
        ...state,
        isSearchOpen: flag,
      };
    }
    case TOGGLE_MOBILESEARCH: {
      const { flag } = action;
      return {
        ...state,
        showOverlay: flag,
        showMobileSearch: flag,
      };
    }
    case EXPECT_MOBILESEARCH: {
      const { flag } = action;
      return {
        ...state,
        shouldFocusMobileSearch: flag,
      };
    }
    case TOGGLE_MOBILEFILTER: {
      return {
        ...state,
        showOverlay: !state.showOverlay,
        showMobileFilter: !state.showMobileFilter,
      };
    }
    case UI_SEARCH_QUERY: {
      const { query, method } = action;
      return {
        ...state,
        query,
        method,
      };
    }
    default:
      return state;
  }
}

const initialConfigState = {
  config: {
    mobile: {
      general: {
        store_logo: '',
        show_rating: true,
        compare_products: true,
        enable_wishlist: true,
        allow_guest_checkout: true,
      },
      orders: {
        allow_reorder: true,
        minimum_order: {
          enable: true,
          min_order_amount: 0,
          include_tax_to_amount: true,
          description: '',
          error_msg_in_cart: '',
        },
      },
      catalog: {
        list_sorting: '',
        allow_guest_review: false,
        product_placeholder: {
          base: '',
          small: '',
          swatch: '',
          thumbnail: '',
        },
      },
      inventory: {
        stock: {
          display_stock: true,
          show_only_left: true,
          show_product_availability: true,
        },
        product_stock: {
          cart_max_qty: 0,
          cart_min_qty: 0,
          out_of_stock_threshold: 0,
          enable_qty_increment: 0,
        },
      },
      account: {
        show_prefix: true,
        show_suffix: true,
        show_mid_name: true,
        show_dob: true,
        show_gender: true,
        show_telephone: true,
      },
      checkout: {
        flat_rate: {
          enable: true,
          title: '',
          method_name: '',
          type: '',
          price: '',
          handling_fee: 0,
          erro_msg: 0,
        },
        free_shipping: {
          enable: true,
          title: '',
          method_name: '',
          min_order_amount: '',
          erro_msg: '',
        },
        table_rates: {
          enable: true,
          title: '',
          method_name: '',
          condition: '',
          handling_fee: '',
          erro_msg: '',
        },
      },
      design: {
        homepage_design: '',
        primary_color: '',
        secondary_color: '',
        button_color: '',
        text_color: '',
      },
    },
    store_front: {
      list_mode: '',
      products_per_page_on_grid_allowed_value: '',
      products_per_page_on_grid_default_value: '',
      products_per_page_on_list_allowed_value: '',
      products_per_page_on_list_default_value: '',
      product_listing_sort_by: '',
      allow_all_products_per_page: '',
    },
    product_reviews: {
      allowed_guests_to_write_reviews: 'true',
    },
    product_view: {
      youtube_api: 'www.youtube.com/video',
    },
    inventory: {
      stock_options: {
        display_out_of_stock: '',
        only_x_left_threshold: '',
        display_products_availability_in_stock_on_storefront: '',
      },
      product_stock_options: {
        manage_stock: '',
        backorders: '',
        maximum_qty_allowed_in_shopping_cart: '',
        out_of_stock_threshold: '',
        minimum_qty_allowed_in_shopping_cart: '',
        enable_qty_increments: '',
      },
      name_and_address_option: {
        number_of_lines_in_a_street_address: '',
        show_prefix: '',
        prefix_dropdown_options: '',
        show_middle_initial: '',
        show_suffix: '',
        suffix_dropdown_options: '',
        show_date_of_birth: '',
        show_tax_vat_number: '',
        show_gender: '',
        show_telephone: '',
        show_company: '',
        show_fax: '',
      },
    },
    rating: {
      general_options: {
        enabled: 'false',
      },
    },
    compare_products: {
      general_options: {
        enabled: 'false',
      },
    },
    wishlist: {
      general_options: {
        enabled: 'false',
      },
      reorder: {
        allow_reorder: 'false',
      },
      minimum_order_amount: {
        enable: 'false',
        minimum_amount: '',
        include_tax_to_amount: '',
        description_message: '',
        error_to_show_in_shopping_cart: '',
      },
    },
    checkout: {
      checkout_options: {
        allow_guest_checkout: '',
        display_billing_address_on: '',
      },
      my_cart_link: {
        display_cart_summary: '',
      },
      shopping_cart_sidebar: {
        display_shopping_cart_sidebar: '',
      },
    },
    shipping_methods: {
      flat_rate: {
        enabled: 'false',
        title: '',
        method_name: '',
        type: '',
        price: '',
        calculate_handling_fee: '',
        handling_fee: '',
        displayed_error_message: '',
      },
      free_shipping: {
        enabled: 'false',
        title: '',
        method_name: '',
        minimum_order_amount: '',
        displayed_error_messge: '',
      },
      table_rates: {
        enabled: 'false',
        title: '',
        method_name: '',
        condition: '',
        include_virtual_products_in_price_calculation: '',
        calculate_handling_fee: '',
        handling_fee: '',
        displayed_error_message: '',
      },
      cash_on_delivery_payment: {
        enabled: 'false',
        title: '',
        new_order_status: '',
        instructions: '',
        minimum_order_total: '',
        maximum_order_total: '',
        sor_order: '',
      },
    },
    design: {
      html_head: {
        favicon_icon: 'any string',
        default_page_title: 'any string',
        page_title_prefix: 'any string',
        page_title_suffix: 'any string',
        default_meta_description: 'any string',
        default_meta_keys: 'any string',
        scripts_and_style_sheets: 'any string',
      },
      header: {
        logo_image: 'any string',
        logo_attribute_width: 'any string',
        logo_attribute_height: 'any string',
        welcome_text: 'any string',
      },
      footer: {
        miscellaneous_html: 'any string',
        copyright: 'any string',
      },
      product_image_watermarks: {
        base: {
          image: 'any string',
          image_opacity: 'any string',
          image_size: 'any string',
          image_position: 'any string',
        },
        thumbnail: {
          image: 'any string',
          image_opacity: 'any string',
          image_size: 'any string',
          image_position: 'any string',
        },
        small: {
          image: 'any string',
          image_opacity: 'any string',
          image_size: 'any string',
          image_position: 'any string',
        },
        swatch_images: {
          image: 'any string',
          image_opacity: 'any string',
          image_size: 'any string',
          image_position: 'any string',
        },
      },
      customer_home_page_template: '',
      configure_theme_styles: {
        colors: {
          primary: '#252525',
          primary_variant: '#333333',
          // accent color
          secondary: '#C3A04B',

          background: '#FFFFFF',
          surface: '#F2F2F2',
          // color on top of primary color
          on_primary: '#FFFFFF',
          // color on top of secondary color
          on_secondary: '#FFFFFF',
          // color on top of background color
          on_background: '#252525',
          // color on top of surface color
          on_surface: '#252525',
        },
      },
      categories: {
        tree_depth_based_on_configurations: '',
      },
    },
  },
};

function configuration (state = initialConfigState, action){
  switch (action.type) {
    case CHANGE_COLOR: {
      const { color } = action;
      console.log(color);
      return state;
    }
    default:
      return state;
  }
}

const initialCustomStyleState = {
  // option: (provided, state) => ({
  //   ...provided,
  //   // borderBottom: '1px dotted pink',
  //   // color: state.isSelected ? 'red' : 'blue',
  //   // padding: 20,
  // }),
  // container: () => ({
  //   // backgroundColor: 'pink',
  //   // border: 'solid pink 1px'
  // }),

  option: (styles, { isSelected, isFocused, isDisabled }) => {
    return {
      ...styles,
      '&:active': {
        backgroundColor: '#25252533',
      },
      backgroundColor: isSelected ? '#25252533' : null || isFocused ? null : null,
      color: isDisabled ? '#888888' : isSelected ? '#252525' : '#444444',
      cursor: isDisabled ? 'not-allowed' : 'default',
      transition: 'all 0.1s',
      '&:hover': {
        color: isDisabled ? '#888888' : isSelected ? '#252525' : isFocused ? '#252525' : '#252525',
        backgroundColor: isDisabled ? null : isSelected ? 'none' : isFocused ? 'none' : null,
      },
    };
  },

  container: (provided, state) => {
    // const outline = state.isFocused ? 'solid pink 1px' : ''

    return {
      ...provided,
      // outline
    };
  },
  input: (provided, state) => {
    const border = state.isFocused ? 'none' : '';

    return {
      ...provided,
      border,
      boxShadow: 'none',
      color: '#252525',
      '& input:disabled': {
        // opacity: 0,
        display: 'none',
      },
    };
  },
  // control: (provided, state) => {
  //   console.log(state)
  //   const border = state.isFocused ? 'solid pink 1px' : ''

  //   // const backgroundColor = state.isFocused ? 'none' : 'coral'
  //   return({
  //     ...provided,
  //     // height: '34px',
  //     // backgroundColor: 'pink',
  //     minHeight: '20px',
  //     border,
  //     boxShadow: 'none',
  //     // backgroundColor
  //   })

  //   // none of react-select's styles are passed to <Control />
  //   // width: 200,
  // },

  control: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      maxWidth: '100%',
      minHeight: '36px',
      maxHeight: '36px',
      boxShadow: 'none',
      border: 'none',
      borderRadius: '0px',
      backgroundColor: isDisabled ? '#25252533' : 'white',
    };
  },
  indicatorSeparator: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      // backgroundColor: '#252525 '
      display: 'none',
    };
  },
  dropdownIndicator: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      // backgroundColor: '#252525',
      fill: 'render',
      // display: 'none',
    };
  },

  singleValue: (styles, { isDisabled, isFocused, isSelected }) => {
    // const opacity = state.isDisabled ? 0.5 : 1;
    // const transition = 'opacity 300ms';
    return {
      ...styles,
      color: isDisabled ? '#252525' : '#252525',
    };
  },
};

function customStyles (state = initialCustomStyleState, action){
  return { ...state };
}

const initialCartIsLoading = {
  isLoading: false,
};

function cartLoading (state = initialCartIsLoading, action){
  switch (action.type) {
    case TOGGLE_CART_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }
    default:
      return state;
  }
}

const initialRemoveCart = {
  fromPaymentSubmit: null,
}

function removeCartFromPaynamics(state = initialRemoveCart, action){
  switch(action.type) {
    case CLEAR_CART_FROM_PAYNAMICS: {
      return {
        ...state,
        fromPaymentSubmit: action.fromPaymentSubmit,
      }
    }
    default:
      return state;
  }
}

const initialCartState = {
  quote_id: null,
  total_cart: [
    {
      converted_at: null,
      is_active: 1,
      is_virtual: 0,
      is_multi_shipping: 0,
      items_count: 0,
      items_qty: 0,
      orig_order_id: 0,
      store_to_base_rate: 0,
      store_to_quote_rate: 0,
      base_currency_code: 'PHP',
      store_currency_code: 'PHP',
      quote_currency_code: 'PHP',
      grand_total: 0,
      base_grand_total: 0,
      checkout_method: null,
      customer_tax_class_id: null,
      customer_prefix: null,
      customer_firstname: null,
      customer_middlename: null,
      customer_lastname: null,
      customer_suffix: null,
      customer_dob: null,
      customer_note: null,
      customer_is_guest: 0,
      applied_rule_ids: null,
      reserved_order_id: null,
      password_hash: null,
      coupon_code: null,
      global_currency_code: 'PHP',
      base_to_global_rate: 1,
      base_to_quote_rate: 1,
      customer_taxvat: null,
      customer_gender: null,
      subtotal: 0,
      base_subtotal: 0,
      subtotal_with_discount: 0,
      base_subtotal_with_discount: 0,
      is_changed: 1,
      trigger_recollect: 0,
      ext_shipping_info: null,
      gift_message_id: null,
      product_url_key: null,
      product_brand_url_key: null,
      is_persistent: 0,
    },
  ],
  cart_item: [],
};

// {
//     Product_brand: 'Natasha',
//     Product_brand_id: 3,
//     Product_image: "https://s3.ap-southeast-1.amazonaws.com/galadriel-staging.codedisruptors.com/123456781548818927568",
//     Product_name: "Nightingale Textured-leather Shoulder Bag",
//     product_id: 2062,
//     price: 58725
// }

function cart (state = initialCartState, action){
  switch (action.type) {
    case POPULATE_CART: {
      // console.log(action)
      // debugger
      const { cart } = action;
      // console.log(cart)
      if (cart) {
        return {
          ...state,
          ...cart,
        };
      } else {
        return null;
      }
    }
    case CART_ADD: {
      const { item } = action;
      return {
        ...state,
        total_cart: [
          {
            ...state.total_cart[0],
            items_qty: state.total_cart[0].items_qty + 1,
            subtotal: state.total_cart[0].subtotal + item.price,
            grand_total: state.total_cart[0].grand_total + item.price,
          },
        ],
        cart_item: [ ...state.cart_item, item ],
      };
    }

    case CART_REMOVE: {
      const { item } = action;
      const cartItems = state.cart_item.filter((itm) => itm.product_id !== item.product_id);

      return {
        ...state,
        total_cart: [
          {
            ...state.total_cart[0],
            items_qty: state.total_cart[0].items_qty - 1,
            subtotal: state.total_cart[0].subtotal - item.price,
            grand_total: state.total_cart[0].grand_total - item.price,
          },
        ],
        cart_item: [ ...cartItems ],
      };
    }
    case MARK_UNAVAILABLE: {
      const { items } = action;
      return {
        ...state,
        cart_item: [ ...items ],
      };
    }
    case CLEAR_CART: {
      return initialCartState;
    }
    case PURGE_ALL:
      return {
        ...initialCartState,
      };
    default:
      return state;
  }
}

function localCart (state = initialCartState, action){
  switch (action.type) {
    case POPULATE_GUEST_CART: {
      const { cart } = action;
      if (cart) {
        return {
          ...state,
          ...cart,
        };
      } else {
        return null;
      }
    }
    case CART_ADD_LOCAL: {
      const { item, quote_id } = action;
      return {
        ...state,
        quote_id: quote_id,
        total_cart: [
          {
            ...state.total_cart[0],
            items_qty: state.total_cart[0].items_qty + 1,
            subtotal: state.total_cart[0].subtotal + item.price,
            grand_total: state.total_cart[0].grand_total + item.price,
          },
        ],
        cart_item: [ ...state.cart_item, item ],
      };
    }
    case CART_REMOVE_LOCAL: {
      const { item } = action;
      const cartItems = state.cart_item.filter((itm) => itm.product_id !== item.product_id);

      return {
        ...state,
        total_cart: [
          {
            ...state.total_cart[0],
            items_qty: state.total_cart[0].items_qty - 1,
            subtotal: state.total_cart[0].subtotal - item.price,
            grand_total: state.total_cart[0].grand_total - item.price,
          },
        ],
        cart_item: [ ...cartItems ],
      };
    }
    case MARK_UNAVAILABLE_LOCAL: {
      const { items } = action;
      return {
        ...state,
        cart_item: [ ...items ],
      };
    }
    case CLEAR_LOCAL_CART: {
      return initialCartState;
    }

    case CLEAR_LOCAL_QUOTE: {
      return {
        ...state,
        quote_id: null,
      };
    }

    default:
      return state;
  }
}
const initialLoginAlertState = {
  showAlert: false,
  alertMessage: '',
  alertHeading: '',
};

function loginAlert (state = initialLoginAlertState, action){
  switch (action.type) {
    case ALERT_DID_FORGOT_PASSWORD: {
      const { content: { showAlert, alertMessage, alertHeading } } = action;
      return {
        ...state,
        showAlert,
        alertMessage,
        alertHeading,
      };
    }
    case ALERT_DID_TOGGLE_ALERT: {
      return {
        ...state,
        showAlert: false,
      };
    }
    default: {
      return {
        ...initialLoginAlertState,
      };
    }
  }
}
const initialDialogState = {
  show: false,
  heading: '',
  contents: undefined,
};

function dialogPage (state = initialDialogState, action){
  switch (action.type) {
    case DIALOG_TOGGLE:
      const { object } = action;
      return {
        ...state,
        ...object,
      };
    default: {
      return {
        ...initialDialogState,
      };
    }
  }
}

const initialAthorization = {
  hasAccess: false,
};

function authorization (state = initialAthorization, action){
  switch (action.type) {
    case SET_AUTH:
      return {
        hasAccess: !state.hasAccess,
      };
    default: {
      return {
        ...initialAthorization,
      };
    }
  }
}

const initialCheckout = {
  shipping_info: {
    same_address: true,
    customer_info: null,
    shipping_address: null,
    billing_address: null,
    flat_rate: null,
    addressFields: {
      shipping: null,
      billing: null,
    },
  },
  payment_method: null,
};

function checkout (state = initialCheckout, action){
  switch (action.type) {
    case SET_SHIPPING_INFO: {
      const { info } = action;
      return {
        ...state,
        shipping_info: {
          ...info,
        },
      };
    }
    case SET_PAYMENT_METHOD: {
      const { method } = action;
      return {
        ...state,
        payment_method: method,
      };
    }
    case RESET_CHECKOUT: {
      return initialCheckout;
    }
    default:
      return state;
  }
}

const initialBrands = {
  all: [],
};

function brands (state = initialBrands, action){
  switch (action.type) {
    case BRANDS_POPULATE_LIST:
      const { brands } = action;
      return {
        ...state,
        all: brands,
      };
    default:
      return state;
  }
}

const initialPromos = {
  all: {},
};

function promos (state = initialPromos, action){
  switch (action.type) {
    case PROMOS_POPULATE_LIST:
      const { promos } = action;
      return {
        ...state,
        all: promos,
      };
    default:
      return state;
  }
}

const initialVoucher = {
  voucherError: false,
  voucherMessage: '',
  breakdown: {
    implicit: false,
    voucher_code: null,
    discount: 0,
    new_grand_total: 0,
    old_grand_total: 0,
    promo_id: null,
  },
};

function voucher (state = initialVoucher, action){
  switch (action.type) {
    case UPDATE_VOUCHER_BREAKDOWN: {
      const { breakdown } = action;
      return {
        ...state,
        breakdown,
      };
    }
    case REMOVE_VOUCHER: {
      return initialVoucher;
    }
    case REMOVE_BREAKDOWN: {
      const res = {
        ...state,
        breakdown: {
          implicit: false,
          voucher_code: null,
          discount: 0,
          new_grand_total: 0,
          old_grand_total: 0,
          promo_id: null,
        },
      };
      // debugger
      return res;
    }
    case VOUCHER_SHOW_ERROR: {
      const { error } = action;
      const voucherError = {
        ...state,
        ...error,
        breakdown: { ...state.breakdown },
      };

      return voucherError;
    }

    case VOUCHER_REMOVE_ERROR: {
      return {
        ...state,
        voucherError: false,
        voucherMessage: '',
        breakdown: { ...state.breakdown },
      };
    }

    default:
      return state;
  }
}

export default combineReducers({
  configuration,
  cartLoading,
  cart,
  localCart,
  customStyles,
  ui,
  category,
  loginAlert,
  dialogPage,
  authorization,
  checkout,
  voucher,
  staticLink,
  staticPages,
  brands,
  removeCartFromPaynamics,
  promos,
});
