import React, { Component, Fragment } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import asyncComponent from 'components/AsyncComponent';
import 'react-toastify/dist/ReactToastify.css';
import { colorTheme } from '../styles/theme';
import { createGlobalStyle } from 'styled-components';
import Paynamics from './Paynamics';
import ReactGA from 'react-ga4';
import { CartAction, AuthAction, VoucherAction } from '../Store';
import * as API from '../utils/API';
import * as Cookie from '../utils/cookie';
import { Firebase } from '../utils/firebase';
import { Offline, Online } from 'react-detect-offline';
import NoInternet from './NoInternet';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import * as firebase from 'firebase';
import 'firebase/database';

const AsyncMain = asyncComponent(() => import('components/Main'));
const AsyncMaintenance = asyncComponent(() => import('components/Maintenance'));
const AsyncSalesDashboard = asyncComponent(() => import('components/SalesDashboard'));

const GlobalStyle = createGlobalStyle`
  ${(props) => props.color_theme}
`;
class App extends Component {
  constructor (props) {
    super(props);
    this.state = {
      newPath: '/',
      shouldRedirect: false,
      under_maintenance: false,
    };
    this.handleLogout = this.handleLogout.bind(this);

    this.handleMaintenance = this.handleMaintenance.bind(this);
    this.checkRequestPath = this.checkRequestPath.bind(this);
    this.handleMaintenance();
  }

  handleMaintenance () {
    const ref = firebase.database().ref(`web/${Firebase.key}`);
    ref.on('value', (snapshot) => {
      const hasAccess = Cookie.hasAccess();
      const onMaintenance = snapshot.val() || false;
      if (onMaintenance) {
        // logs out the user
        if (hasAccess) {
          this.handleLogout();
        }
      }
      console.log(this.state, onMaintenance);
      this.setState({
        under_maintenance: onMaintenance,
      });
    });
  }

  async handleLogout () {
    this.setState({
      isLoading: true,
    });
    try {
      // const logout = await API.logout()
      API.logout().then((_) => {
        this.setState(
          {
            isLoading: false,
          },
          () => {
            Cookie.removeUser();
            this.props.toggleAuth();
            this.props.clearCart();
            this.props.removeVoucher();
            const { staticLink } = this.props;
            const { static_pages } = staticLink;

            this.props.history.push(static_pages ? static_pages['10'].url_key : '/login'); // login page
          },
        );
      });
    } catch (err) {
      this.setState({
        isLoading: false,
      });
      console.log(err);
    }
  }

  componentDidMount () {
    //   if(!window.navigator.onLine){
    //     toastPop({ message: 'No internet connection found.', type: 'error' })
    //   }
    // window.addEventListener('offline', () => {
    //   toastPop({ message: 'No internet connection found.', type: 'error' })
    // });
    //   window.addEventListener('online', () => {
    //     document.location.reload(true)
    // });
    // export function toastPop({message, type = 'default', autoClose= 3000, onClose, onOpen, position = 'top-center'}) {

    const { history, location: { pathname } } = this.props;
    this.checkRequestPath(pathname);
    this.unlisten = history.listen((location) => {
      toast.dismiss();
      this.checkRequestPath(location.pathname);
      // ReactGA.pageview(location.pathname);
      ReactGA.send({ hitType: "pageview", page: location.pathname});

    });
  }
  componentWillUnmount () {
    this.unlisten();
  }

  async checkRequestPath (pathname) {
    try {
      // the pathname we receive shouldn't have '/' characters
      // split the pathname by '/' then start reading the last index
      const splitted = pathname.split('/');
      let last = splitted[splitted.length - 1];

      if (splitted.length === 2 && splitted[0] === '') {
        // this is static
        last = `/${last}`;
      }
      // get current path
      let response = await API.checkLatestPath({ path: last });
      const { data: { path } } = response;
      if (path !== last) {
        this.setState({
          shouldRedirect: true,
          newPath: path,
        });
      } else {
        this.setState({
          shouldRedirect: false,
        });
      }
    } catch (error) {
      // let's figure this out later
      this.setState({
        shouldRedirect: false,
      });
    }
  }

  renderBody () {
    const { configuration } = this.props;
    const { under_maintenance, maintenance_message, shouldRedirect, newPath } = this.state;
    const colors = configuration.config.design.configure_theme_styles.colors;
    return shouldRedirect ? (
      <Redirect to={newPath} />
    ) : (
      <Fragment>
        <GlobalStyle color_theme={colorTheme(colors || {})} />
        <Online
          polling={{
            interval: 600000,
          }}>
          {under_maintenance ? (
            <AsyncMaintenance message={maintenance_message} />
          ) : (
            <Fragment>
              <Switch>
                {/* <Route exact path="/xml/feed" component={AsyncFeed}/> */}
                {/* <Route exact path="/503" component={ AsyncMaintenance } /> */}
                <Route exact path='/dashboard' component={AsyncSalesDashboard} />
                <Route path='/' component={AsyncMain} />
              </Switch>
            </Fragment>
          )}
        </Online>
        <Offline
          polling={{
            interval: 600000,
          }}>
          <NoInternet />
        </Offline>
      </Fragment>
    );
  }

  render () {
    const { location: { pathname } } = this.props;
    const path = pathname.split('/');

    if (path[1] !== 'paynamics') {
      return this.renderBody();
    } else {
      return <Route exact path='/paynamics/:quoteID' component={Paynamics} />;
    }
  }
}

function mapStateToProps ({ configuration, staticLink }){
  return { configuration, staticLink };
}

function mapDispatchToProps (dispatch){
  return {
    clearCart: () => {
      dispatch(CartAction.clearCart());
    },
    toggleAuth: () => dispatch(AuthAction.toggleAuth()),
    removeVoucher: () => {
      dispatch(VoucherAction.removeVoucher());
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
