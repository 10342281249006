import React from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import * as Cookie from './cookie';
import UrlSafeString from 'url-safe-string';
// import { site_url } from '../../package.json'
import config from './config';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

const env = process.env.REACT_APP_ENV;
const site_url = config[env].site_url;

export const urlSafeString = new UrlSafeString();

export function restructureCategory (categories){
  // console.log(categories)
  if (categories && categories['sub-categories'].length > 0) {
    return categories['sub-categories'].map((category) => {
      const categoryID = category['entity_id'];
      const categoryName = category.attributes ? category.attributes.name || category.attributes[0].value : '';
      // console.log(category.attributes.name)
      // console.log(category.attributes[0].value)
      const urlKey = category.attributes.url_key;

      return {
        categoryName: categoryName,
        url: `/category/${urlKey || categoryID}`,
        subCategory: restructureCategory(category) || [],
      };
    });
  }
}

export function toastPop ({ message, type = 'default', autoClose = 3000, onClose, onOpen, position = 'top-center' }){
  toast(message, { type, autoClose, onClose, onOpen, position });
}

export function trimObjValues (obj){
  return Object.keys(obj).reduce((acc, curr) => {
    acc[curr] = obj[curr] ? obj[curr].trim() : obj[curr];
    return acc;
  }, {});
}

export function scrollTop (){
  window.scrollTo(0, 0);
}

// eslint-disable-next-line
Number.prototype.pad = function (size){
  var s = String(this);
  while (s.length < (size || 2)) {
    s = '0' + s;
  }
  return s;
};

export function saveUserQuoteId (quote_id){
  // console.log(quote_id)
  const user = Cookie.getUser();
  const addedQ = {
    //get new quote id and save to cookie
    ...user,
    quote_id,
  };
  Cookie.saveUser(addedQ);
}

export function currencyFormat (num){
  if (typeof num !== 'number') return 0;
  return `₱ ${num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
}

export function extractNumbers (str){
  // console.log(str)
  const num = str.replace('₱', '');
  // eslint-disable-next-line
  const finnum = num.replace(/\,/g, '');
  // console.log(parseFloat(finnum))
  return parseFloat(finnum);
}

export function toQueryString (params){
  return Object.keys(params).map((key) => key + '=' + params[key]).join('&');
}

export function validateEmail (email){
  // eslint-disable-next-line
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePHMobileNumber (number){
  let re = /^(09|\+639)\d{9}$/;
  return re.test(number);
}

export function getIndex (id, array){
  let index = -1;
  for (let idx = 0; idx < array.length; idx++) {
    if (array[idx].entity_id === id) {
      index = idx;
      break;
    }
  }
  return index;
}

export function addSlashURL (url_link){
  if (url_link) {
    if (url_link.toString().charAt(0) !== '/') {
      return `/${url_link}`;
    }
  }
  return url_link;
}

export function getErrorAddressField (err){
  switch (err) {
    case 'region': {
      return 'Please select your Province';
    }
    case 'municipality': {
      return 'Please select your City';
    }
    case 'barangay': {
      return 'Please select your Barangay';
    }
    case 'street_address': {
      return 'Please enter your Street address';
    }
    default:
      return err;
  }
}

export function getSelectedItemInArray ({ value, key, array }){
  if (value) {
    const filtered = array.filter((item) => {
      return item.value[key] === value.trim();
    });
    const result = filtered[0] || null;
    return result
      ? result
      : {
          value: {
            [key]: value.trim(),
          },
          label: value.trim(),
        };
  }
  return {
    value: undefined,
    label: 'Not found',
  };
}

export function getFilteredArray ({ selected, selectedKey, array, key, requireSelect }){
  // if (selected === undefined || selected === null) return []
  // if (selected.value === undefined) return []
  // console.log(array.RECORDS)
  let filteredArray = array;
  if (selected && selected.value) {
    filteredArray = filteredArray.filter((item) => {
      return selected.value[selectedKey] === item[selectedKey];
    });
  }
  filteredArray = filteredArray
    .map((item) => ({
      value: {
        ...item,
      },
      label: item[key],
    }))
    .sort(function (aVal, bVal){
      var nameA = aVal.label.toUpperCase(); // ignore upper and lowercase
      var nameB = bVal.label.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  return requireSelect ? (selected ? filteredArray : []) : filteredArray;
}

export function getRandomItems ({ array, count, exception, key }){
  let randomItems = [];
  let copyArray = [ ...array ];
  for (let i = 0; i <= count - 1; i++) {
    let selected = [];
    if (exception) {
      let randIndex = getRandomInt(0, copyArray.length - 1);
      let prediction = undefined;
      do {
        randIndex = getRandomInt(0, copyArray.length - 1);
        prediction = copyArray[randIndex];
      } while (prediction[key] === exception[key]);
      // the randIndex we got should not contain the same item
      selected = copyArray.splice(randIndex, 1);
    } else {
      selected = copyArray.splice(getRandomInt(0, copyArray.length - 1), 1);
    }
    randomItems = [ ...randomItems, ...selected ];
  }
  return randomItems;
}

export function getItems ({ array, count }){
  return array.slice(0, count).map((i) => i);
}

export function normalizeLink (string){
  if (string) {
    return string.replace(/ /gi, '-').toLowerCase();
  }
}

export function getImageDimensions (url, callback){
  const img = new Image();

  img.onload = function (){
    const height = img.height.toString();
    const width = img.width.toString();

    // code here to use the dimensions
    return callback({
      dimension: {
        height,
        width,
      },
    });
  };

  img.onerror = function (){
    // code here to use the dimensions
    return callback({
      dimension: {
        height: '1200',
        width: '600',
      },
    });
  };

  img.src = url;
}

export function getDefaultMetas (meta){
  let meta_image = 'https://s3-ap-southeast-1.amazonaws.com/customer.luxein.com/OG+Image.png';
  const tags = {
    meta_title:
      'Your Premiere Luxury Exchange | Your Authentic Pre-loved & Unused Brand New Branded Items Shopping Central in PH | Luxe In',
    meta_description:
      'Shop Online for Preloved and 2nd Hand Watches, Electronics, Designer Bags, Jewelry | Up to 70% Discount✓, 100% Authentic✓, Same-Day-Dispatch✓, Cash on Delivery | Luxe In',
    meta_keywords: '',
    image: meta_image,
  };
  return {
    ...meta,
    meta_title: meta.meta_title ? meta.meta_title : tags.meta_title,
    meta_description: meta.meta_description ? meta.meta_description : tags.meta_description,
    meta_keywords: meta.meta_keywords ? meta.meta_keywords : tags.meta_keywords,
    image: meta.image ? meta.image : tags.image,
  };
}

export function getProductMeta (product){
  let tags = {
    meta_title:
      'Your Premiere Luxury Exchange | Your Authentic Pre-loved & Unused Brand New Branded Items Shopping Central in PH | Luxe In',
    meta_description:
      'Shop Online for Preloved and 2nd Hand Watches, Electronics, Designer Bags, Jewelry | Up to 70% Discount✓, 100% Authentic✓, Same-Day-Dispatch✓, Cash on Delivery | Luxe In',
    meta_keywords: '',
    url_key: '',
  };
  if (!product) return tags;
  if (product.attribute_group) {
    let meta_image = 'https://s3-ap-southeast-1.amazonaws.com/customer.luxein.com/OG+Image.png';
    // v2 support
    const { attribute_group } = product || { attribute_group: { 'search-engine-optimization': null } };
    const { name } = product;
    const { meta_description, meta_keyword, meta_title, url_key } = attribute_group['search-engine-optimization'];
    const { image } = attribute_group['image-management'];
    tags.meta_title = meta_title || name;
    if (meta_description) tags.meta_description = meta_description;
    if (meta_keyword) tags.meta_keyword = meta_keyword;
    if (url_key) tags.url_key = `/product/${url_key}`;
    tags.image = image ? image : meta_image;
  } else {
    // v1 support

    let meta_image = 'https://s3-ap-southeast-1.amazonaws.com/customer.luxein.com/OG+Image.png';
    tags.meta_title = product.meta_title || product.name;
    tags = {
      meta_title: product ? (product.meta_title ? product.meta_title : tags.meta_title) : tags.meta_title,
      meta_description: product
        ? product.meta_description ? product.meta_description : tags.meta_description
        : tags.meta_description,
      meta_keywords: product ? (product.meta_keyword ? product.meta_keyword : tags.meta_keywords) : tags.meta_keywords,
      url_key: product ? `/product/${product.url_key}` : `/product/${tags.url_key}`,
      image: product ? (product.image ? product.image : meta_image) : meta_image,
    };
  }
  return tags;
}
// Converts https to http ONLY
export function getHttpUrl (url){
  if (url) {
    return url.replace('https', 'http');
  } else {
    return url;
  }
}

export function applyURL (url){
  let correctUrl = '';
  if (url.charAt(0) === '/') {
    correctUrl = url;
  } else {
    correctUrl = `/${url}`;
  }
  return correctUrl;
}

export function correctUrlKeyUsingMeta (meta){
  if (!meta) return '';
  const { url_key } = meta;
  if (!url_key) return '';
  let correctUrl = applyURL(url_key);
  return meta.url_identifier ? `${meta.url_identifier}${correctUrl}` : correctUrl;
}

export function getActualMetaWithDimensions (metadata, dimension){
  const meta = getDefaultMetas(metadata);
  const url = `${site_url}${correctUrlKeyUsingMeta(meta)}`;
  return (
    <Helmet>
      <title>{meta.meta_title}</title>
      <link rel='canonical' href={url} />
      <meta name='description' content={meta.meta_description} />
      <meta name='keywords' content={meta.meta_keywords} />
      <meta property='og:site_name' content='Luxe In' />
      <meta property='og:description' content={meta.meta_description} />
      <meta property='og:url' content={url} />
      <meta property='og:title' content={meta.meta_title} />
      <meta property='og:image' content={getHttpUrl(meta.image)} />
      <meta property='og:image:secure_url' content={meta.image} />
      <meta property='og:image:width' content={dimension.width} />
      <meta property='og:image:height' content={dimension.height} />
    </Helmet>
  );
}

export function getActualMetaTags (metadata){
  const meta = getDefaultMetas(metadata);
  let url = `${site_url}${correctUrlKeyUsingMeta(meta)}`;
  if (metadata.url_key && metadata.url_key === '/') {
    url = `${site_url}/index-v1.html`;
  }
  return (
    <Helmet>
      <title>{meta.meta_title}</title>
      <link rel='canonical' href={url} />
      <meta name='description' content={meta.meta_description} />
      <meta name='keywords' content={meta.meta_keywords} />
      <meta property='og:site_name' content='Luxe In' />
      <meta property='og:url' content={url} />
      <meta property='og:description' content={meta.meta_description} />
      <meta property='og:title' content={meta.meta_title} />
      <meta property='og:image' content={getHttpUrl(meta.image)} />
      <meta property='og:image:secure_url' content={meta.image} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />
    </Helmet>
  );
}

export function getPageURL (pages, onKey, default_url){
  if (pages) {
    // validate if rqp or url starts with forward slash
    // if not, add a forward slash at the beginning of the url or rqp;
    const regex = /^\//;
    const rqp = pages[onKey] && pages[onKey].request_path ? pages[onKey].request_path : '';
    const url = pages[onKey] && pages[onKey].url_key ? pages[onKey].url_key : '';
    const RQPwithSlash = rqp ? regex.test(rqp) : true;
    const URLwithSlash = url ? regex.test(url) : true;
    const request_path = RQPwithSlash ? rqp : `/${rqp}`;
    const url_key = URLwithSlash ? url : `/${url}`;

    return rqp ? request_path : url_key;
  }

  return default_url;
}

export function scrollToTop (){
  window.scrollTo(0, 0);
}

export function checkProductPromo (prod){
  // check is special_date is not 00
  if (prod.special_to_date === '0000-00-00 00:00:00' || prod.special_to_date === undefined) return false;
  if (prod.special_from_date === '0000-00-00 00:00:00' || prod.special_from_date === undefined) return false;

  const from = moment(prod.special_from_date);
  const to = moment(prod.special_to_date);
  // debugger
  const range = moment().range(from, to);
  const today = moment();
  return range.contains(today);
}

export function QueryStringToJSON (location){
  if (location.search.indexOf('?') === -1) return undefined;
  var pairs = location.search.slice(1).split('&');

  var result = {};
  pairs.forEach(function (pair){
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });

  return JSON.parse(JSON.stringify(result));
}

export function getSpecialFlag (from, to){
  if (!from && !to) return false;
  if (!from) {
    from = moment().format('YYYY-MM-DD');
  }
  if (!to) {
    to = moment().format('YYYY-MM-DD');
  }
  let date_from = moment(from).format('YYYY-MM-DD');
  let date_to = moment(to).format('YYYY-MM-DD');
  let now = moment().format('YYYY-MM-DD');
  return { isSpecial: moment(now).isBetween(date_from, date_to, null, '[]') };
}

export function getUpcomingSaleFlag (from, to){
  if (!from && !to) return false;
  if (!from) {
    from = moment().format('YYYY-MM-DD');
  }
  if (!to) {
    to = moment().format('YYYY-MM-DD');
  }
  let date_from = moment(from).format('YYYY-MM-DD');
  let date_to = moment(to).format('YYYY-MM-DD');
  let now = moment().format('YYYY-MM-DD');
  return { isClickable: moment(now).isBetween(date_from, date_to, null, '[]') };
}

/**
 * Returns a random integ   er between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt (min, max){
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
