// import * as Help from 'utils/helpers';

export const FOOD_UPDATE_QUERY = 'FOOD_UPDATE_QUERY';
export const FOOD_UPDATE_ITEMS = 'FOOD_UPDATE_ITEMS';

export const MENU_APPLY_ITEMS = 'MENU_APPLY_ITEMS';

export const SET_AUTH = 'SET_AUTH';

export const POPULATE_STATIC_LINKS = 'POPULATE_STATIC_LINKS';

export const POPULATE_CART = 'POPULATE_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const CART_ADD = 'CART_ADD';
export const CART_REMOVE = 'CART_REMOVE';
export const MARK_UNAVAILABLE = 'MARK_UNAVAILABLE';

export const TOGGLE_CART_LOADING = 'TOGGLE_CART_LOADING';
export const POPULATE_GUEST_CART = 'POPULATE_GUEST_CART';
export const CART_ADD_LOCAL = 'CART_ADD_LOCAL';
export const CLEAR_LOCAL_CART = 'CLEAR_LOCAL_CART';
export const CLEAR_LOCAL_QUOTE = 'CLEAR_LOCAL_QUOTE';
export const CART_REMOVE_LOCAL = 'CART_REMOVE_LOCAL';
export const MARK_UNAVAILABLE_LOCAL = 'MARK_UNAVAILABLE_LOCAL';
export const CONFIGURATION = 'CONFIGURATION';
export const CHANGE_COLOR = 'CHANGE_COLOR';

export const CLEAR_CART_FROM_PAYNAMICS = 'CLEAR_CART_FROM_PAYNAMICS';

export const STYLES = 'STYLES';

export const TOGGLE_OVERLAY = 'TOGGLE_OVERLAY';
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const TOGGLE_MOBILESEARCH = 'TOGGLE_MOBILESEARCH';
export const EXPECT_MOBILESEARCH = 'EXPECT_MOBILESEARCH';
export const TOGGLE_MOBILEFILTER = 'TOGGLE_MOBILEFILTER';
export const UI_SEARCH_QUERY = 'UI_SEARCH_QUERY';
// Call when purging all redux identities
export const PURGE_ALL = 'PURGE_ALL';

export const POPULATE_CATEGORY = 'POPULATE_CATEGORY';

// LOGIN ALERT
export const ALERT_DID_FORGOT_PASSWORD = 'ALERT_DID_FORGOT_PASSWORD';
export const ALERT_DID_TOGGLE_ALERT = 'ALERT_DID_TOGGLE_ALERT';

// DIALOG
export const DIALOG_TOGGLE = 'DIALOG_TOGGLE';

// CHECKOUT

export const SET_SHIPPING_INFO = 'SET_SHIPPING_INFO';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const RESET_CHECKOUT = 'RESET_CHECKOUT';

export const UPDATE_VOUCHER_BREAKDOWN = 'UPDATE_VOUCHER_BREAKDOWN';
export const REMOVE_VOUCHER = 'REMOVE_VOUCHER';
export const REMOVE_BREAKDOWN = 'REMOVE_BREAKDOWN';
export const VOUCHER_SHOW_ERROR = 'VOUCHER_SHOW_ERROR';
export const VOUCHER_REMOVE_ERROR = 'VOUCHER_REMOVE_ERROR';

export const BRANDS_POPULATE_LIST = 'BRANDS_POPULATE_LIST';

export const PROMOS_POPULATE_LIST = 'PROMOS_POPULATE_LIST';

export const STATIC_SET_PAGE = 'STATIC_SET_PAGE';

export const StaticPageAction = {
  set: setPage,
};

function setPage (page, url_key){
  return {
    type: STATIC_SET_PAGE,
    url_key,
    page,
  };
}

export const BrandAction = {
  populateAllBrands: populateAllBrands,
};

function populateAllBrands (brands){
  return {
    type: BRANDS_POPULATE_LIST,
    brands,
  };
}

export const PromoAction = {
  populateAllPromos: populateAllPromos,
};

function populateAllPromos (promos){
  return {
    type: PROMOS_POPULATE_LIST,
    promos,
  };
}

export const StaticLinksAction = {
  populateStaticLinks: populateStaticLinks,
};

function populateStaticLinks (links){
  // console.log(links)
  return {
    type: POPULATE_STATIC_LINKS,
    links,
  };
}

export const CategoryAction = {
  populateCategory: populateCategory,
};

function populateCategory (categories){
  return {
    type: POPULATE_CATEGORY,
    categories,
  };
}

export const UIAction = {
  toggleOverlay,
  toggleSearch,
  toggleSearchLoading,
  toggleMobileSearch,
  expectMobileSearch,
  toggleMobileFilter,
  changeQuery,
};

function toggleMobileFilter (){
  return {
    type: TOGGLE_MOBILEFILTER,
  };
}

function toggleSearch ({ flag }){
  return {
    type: TOGGLE_SEARCH,
    flag,
  };
}

function toggleSearchLoading ({ flag }){
  return {
    type: TOGGLE_LOADING,
    flag,
  };
}

function toggleMobileSearch ({ flag }){
  return {
    type: TOGGLE_MOBILESEARCH,
    flag,
  };
}

function expectMobileSearch ({ flag }){
  return {
    type: EXPECT_MOBILESEARCH,
    flag,
  };
}

function toggleOverlay (){
  return {
    type: TOGGLE_OVERLAY,
  };
}

function changeQuery ({ query, method }){
  return {
    type: UI_SEARCH_QUERY,
    query,
    method,
  };
}

export const FoodAction = {
  updateQuery: foodWillUpdateQuery,
  updateItems: foodWillUpdateItems,
};

export const ConfigurationAction = {
  changeColor: colorWillChange,
};

function colorWillChange (color){
  return {
    type: CHANGE_COLOR,
    color,
  };
}

export const StyleAction = {
  getStyles,
};

// function getConfiguration ({ config }) {
//   return{
//     type: CONFIGURATION,
//     config
//   }
// }

function getStyles ({ styles }){
  return {
    type: STYLES,
    styles,
  };
}

function foodWillUpdateQuery ({ value }){
  return {
    type: FOOD_UPDATE_QUERY,
    value,
  };
}

function foodWillUpdateItems ({ items }){
  return {
    type: FOOD_UPDATE_ITEMS,
    items,
  };
}

export const MenuAction = {
  applyItems: applyItems,
};

function applyItems ({ menuItems, userType }){
  return {
    type: MENU_APPLY_ITEMS,
    menuItems,
    userType,
  };
}

export const LocalCartAction = {
  addLocalCart,
  removeLocalCart,
  markUnavailableLocal,
  clearLocalCart,
  clearLocalQuote,
  populateGuestCart,
};

function clearLocalCart (){
  return {
    type: CLEAR_LOCAL_CART,
  };
}

function clearLocalQuote (){
  return {
    type: CLEAR_LOCAL_QUOTE,
  };
}

function populateGuestCart (cart){
  // console.log('updating cart')
  // debugger
  return {
    type: POPULATE_GUEST_CART,
    cart,
  };
}

function addLocalCart (item, quote_id){
  return {
    type: CART_ADD_LOCAL,
    item,
    quote_id,
  };
}

function removeLocalCart (item){
  // console.log(item)
  return {
    type: CART_REMOVE_LOCAL,
    item,
  };
}

function markUnavailableLocal (items){
  return {
    type: MARK_UNAVAILABLE_LOCAL,
    items,
  };
}

export const CartAction = {
  populateCart,
  clearCart,
  addCart,
  removeCart,
  markUnavailable,
};

export const CartLoadingAction = {
  toggleCartLoading,
  clearCartFromPaynamics
};

function toggleCartLoading (bool){
  return {
    type: TOGGLE_CART_LOADING,
    bool,
  };
}

function clearCartFromPaynamics (fromPaymentSubmit) {
  return {
    type: CLEAR_CART_FROM_PAYNAMICS,
    fromPaymentSubmit,
  }
}

function markUnavailable (items){
  return {
    type: MARK_UNAVAILABLE,
    items,
  };
}

function addCart (item){
  return {
    type: CART_ADD,
    item,
  };
}

function removeCart (item){
  return {
    type: CART_REMOVE,
    item,
  };
}

function clearCart (){
  return {
    type: CLEAR_CART,
  };
}

function populateCart (cart){
  // console.log('updating cart')
  // debugger
  return {
    type: POPULATE_CART,
    cart,
  };
}

function purgeAll (){
  return {
    type: PURGE_ALL,
  };
}

export const PurgeAction = {
  purge: purgeAll,
};

function didForgotPassword ({ content }){
  return {
    type: ALERT_DID_FORGOT_PASSWORD,
    content,
  };
}

function didToggleAlert (){
  return {
    type: ALERT_DID_TOGGLE_ALERT,
  };
}

export const ForgotAction = {
  forgotPassword: didForgotPassword,
  toggle: didToggleAlert,
};

function didToggleDialog ({ object }){
  return {
    type: DIALOG_TOGGLE,
    object,
  };
}

export const DialogAction = {
  toggle: didToggleDialog,
};

function toggleAuth (){
  return {
    type: SET_AUTH,
  };
}

export const AuthAction = {
  toggleAuth,
};

export const CheckoutAction = {
  updatedShippingInfo,
  updatePaymentMethods,
  resetCheckout,
};

function resetCheckout (){
  return {
    type: RESET_CHECKOUT,
  };
}

function updatePaymentMethods (method){
  return {
    type: SET_PAYMENT_METHOD,
    method,
  };
}

function updatedShippingInfo (info){
  return {
    type: SET_SHIPPING_INFO,
    info,
  };
}

export const VoucherAction = {
  updateVoucher,
  removeVoucher,
  removeBreakdown,
  showVoucherError,
  removeVoucherError,
};

function updateVoucher (breakdown){
  return {
    type: UPDATE_VOUCHER_BREAKDOWN,
    breakdown,
  };
}

function removeVoucher (){
  return {
    type: REMOVE_VOUCHER,
  };
}

function removeBreakdown (){
  return {
    type: REMOVE_BREAKDOWN,
  };
}

function showVoucherError (error){
  return {
    type: VOUCHER_SHOW_ERROR,
    error,
  };
}

function removeVoucherError (){
  return {
    type: VOUCHER_REMOVE_ERROR,
  };
}
