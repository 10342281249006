export default {
  development: {
    route: 'http://127.0.0.1:5000/api/v1',
    fbPixelID: '',
    gaID: 'G-V8TKW0XQEK',
    gtmID: 'GTM-P439JDT',
    firebaseKey: 'staging_maintenance',
    fbButtonKey: 'staging_show_fb',
    paynamicsButtonKey: 'staging_show_paynamics',
    debug: true,
    site_url: 'http://staging.luxein.com.s3-website-ap-southeast-1.amazonaws.com',
    facebookID: '1798691006944317',
  },
  staging: {
    route: 'http://api-staging.luxein.com/api/v1',
    fbPixelID: '',
    gaID: 'G-V8TKW0XQEK',
    gtmID: 'GTM-P439JDT',
    firebaseKey: 'staging_maintenance',
    fbButtonKey: 'staging_show_fb',
    paynamicsButtonKey: 'staging_show_paynamics',
    debug: true,
    site_url: 'http://staging.luxein.com.s3-website-ap-southeast-1.amazonaws.com',
    facebookID: '1798691006944317',
  },
  test: {
    route: 'http://galadriel-backend-test.codedisruptors.com:5000/api/v1',
    fbPixelID: '',
    gaID: 'G-V8TKW0XQEK',
    gtmID: 'GTM-P439JDT',
    debug: true,
    firebaseKey: 'test_maintenance',
    fbButtonKey: 'test_show_fb',
    paynamicsButtonKey: 'test_show_paynamics',
    site_url: 'http://galadriel-customer-test.codedisruptors.com.s3-website-ap-southeast-1.amazonaws.com',
    facebookID: '1798691006944317',
  },
  production: {
    route: 'https://api-galadriel.luxein.com/api/v1',
    fbPixelID: '735460744624710',
    gaID: 'G-V8TKW0XQEK',
    gtmID: 'GTM-P439JDT',
    debug: false,
    firebaseKey: 'prod_maintenance',
    fbButtonKey: 'prod_show_fb',
    paynamicsButtonKey: 'prod_show_paynamics',
    site_url: 'https://luxein.com',
    facebookID: '360528381308732',
  },
};
